import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

interface Props {
  children: React.ReactNode;
}

function PriceWrapper(props: Props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "stretch" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function Pricing({
  onButtonClick,
  userText
}: {
  onButtonClick?: (plan: string) => void;
  userText?: string;
}) {
  return (
    <Box py={20} px={{ base: 10, sm: 10, md: 0 }} maxW={"6xl"} mx={"auto"}>
      <VStack spacing={2} textAlign="center">
        <Heading
          as="h2"
          color={"#01688b"}
          fontSize={{ base: "4xl", md: "6xl" }}
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
        >
          {/* {onButtonClick ? "Select" : ""} Plan{onButtonClick ? "" : "s"} */}
          {onButtonClick ? "Your Plan" : "Optimization Plan"}
        </Heading>
        {/* <Text fontSize="lg" color={"gray.500"}>
          Start with 14-day free trial. No credit card needed. Cancel at
          anytime.
        </Text> */}
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 20 }}
        py={10}
        // bgColor={"red"}
      >
        <PriceWrapper>
          <Box
            position="relative"
            bgColor={"#01688b"}
            color={"white"}
            borderRadius={"xl"}
            h="100%"
          >
            <Box py={4} px={12} h={"25%"}>
              <Heading
                fontWeight="semibold"
                fontSize={{ base: "3xl", md: "4xl" }}
                className="font-mantblack"
                style={{
                  fontFamily: "Montblancbold"
                }}
                color={"white"}
              >
                {onButtonClick ? "Optimization" : ""}
              </Heading>
              <Heading
                mt={2}
                fontSize="2xl"
                fontWeight="600"
                className="font-mantblack"
                style={{
                  fontFamily: "Montblancbold"
                }}
                color={"white"}
              >
                $245/month After
              </Heading>
              <Text color={"white"} mt={2} textDecorationLine={"line-through"}>
                $195 initial lab fee
              </Text>
              <Text color={"white"} fontWeight={"bold"} mt={2}>
                {userText}
              </Text>
            </Box>
            <VStack py={4} borderBottomRadius={"xl"} h="70%" pos="relative">
              <List spacing={3} textAlign="start" px={12}>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Initial Labs Covering 60+ Biomarkers
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Supplements & Micronutrients
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Follow-up Labs with Physician Consults Semi-Annually
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Dynamic Opt Performance Score based on your health
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Initial Physician Consult & Treatment Plan
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Compounded Optimization Medications
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Tailored Optimization Prescriptions
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Access to a la Carte Medications
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  HIPAA-Compliant Communication
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Increase Energy & Lean Muscle Mass
                </ListItem>
                <ListItem color="white">
                  <ListIcon
                    as={FaCheckCircle}
                    color="white"
                    backgroundColor={"#01688b"}
                  />
                  Reduce Likelihood of Chronic Disease
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                {onButtonClick && (
                  <Button
                    w="full"
                    variant="outline"
                    bgColor={"white"}
                    color={"#01688b"}
                    onClick={() => onButtonClick("Optimization")}
                  >
                    Continue
                  </Button>
                )}
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        {/* <PriceWrapper>
          <Box py={4} px={12}>
            <Heading
              fontWeight="semibold"
              fontSize={{ base: "3xl", md: "4xl" }}
              className="font-mantblack"
              style={{
                fontFamily: "Montblancbold"
              }}
              color={"#01688b"}
            >
              Longevity
            </Heading>
            <Heading
              mt={2}
              fontSize="2xl"
              fontWeight="600"
              className="font-mantblack"
              style={{
                fontFamily: "Montblancbold"
              }}
              color={"#01688b"}
            >
              $695/month After
            </Heading>
            <Text color={"#01688b"} mt={2} textDecorationLine={"line-through"}>
              $645 initial lab & epigenetic testing fee
            </Text>
            <Text color={"#01688b"} mt={2}>
              {userText}
            </Text>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Initial Labs Covering 60+ Biomarkers
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Supplements & Micronutrients
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Follow-up Labs with Physician Consults Semi-Annually
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Dynamic Opt Performance Score based on your health
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Initial Physician Consult & Treatment Plan
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Compounded Optimization Medications
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Tailored Optimization Prescriptions
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Access to a la Carte Medications
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                HIPAA-Compliant Communication
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Increase Energy & Lean Muscle Mass
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Reduce Likelihood of Chronic Disease
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Epigenetic Testing
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#01688b" />
                Reverse Biological Age & Slow Aging to feel your best
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              {onButtonClick && (
                <Button
                  w="full"
                  variant="outline"
                  bgColor={"#01688b"}
                  color={"white"}
                  onClick={() => onButtonClick("Longevity")}
                >
                  Select
                </Button>
              )}
            </Box>
          </VStack>
        </PriceWrapper> */}
      </Stack>
      {onButtonClick && (
        <VStack spacing={2} textAlign="center">
          <Text fontSize="lg" textAlign={"center"} color={"#676767"}>
            Opt Health accepts most HSA & FSA cards for payment and
            reimbursement. Upon sign up, please enter your HSA/FSA debit card
            number for payment. If you don’t have an HSA/FSA debit card, email
            info@getopt.com for an itemized invoice you can submit for HSA/FSA
            reimbursement. Approval is subject to coverage provided by each
            HSA/FSA program and cannot be guaranteed by Opt.
          </Text>
        </VStack>
      )}
    </Box>
  );
}

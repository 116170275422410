import { useEffect, useState } from "react";
import { extractAndFetch } from "../../config/apis";
import { ALLOWED_LANDING_PAGE_HOSTS } from "../../config/constants";
import { User } from "../../types";
import { useNavigate } from "react-router-dom";
import { LinkProps } from "@chakra-ui/react";
export const WrapperHOC = () => {
  const [user, setUser] = useState<User>({} as User);
  const [host] = useState(window.location.host);
  const navigate = useNavigate();
  const fetchData = async () => {
    const domain = window.location.host;

    try {
      let slug = window.location.pathname.split("/")[1];
      if (
        !slug &&
        (domain === "www.pomelo.refer.us" ||
          domain === "www.lyka.refer.us" ||
          domain === "www.getopt.refer.us")
      ) {
        navigate("/terms");
        return;
      }
      const response = await extractAndFetch();
      // console.log(response);
      if (response.status === 200) {
        setUser(response.user);
        if (host === "localhost:3001" || host === "www.getopt.refer.us") {
          document.title = `Accept ${
            response.user?.full_name?.split?.(" ")?.[0] || ""
          }'s VIP Invite`;
        }
      } else {
        navigate("/error");
        // setUser({ full_name: "Fahad Jawed", email: "asd", id: 2 });
      }
    } catch (error) {
      // setUser({ full_name: "Fahad Jawed", email: "asd", id: 2 });

      navigate("/error");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const Comp = ALLOWED_LANDING_PAGE_HOSTS[host];
  return (
    // <div>
    <Comp user={user} />
    // </div>
  );
};

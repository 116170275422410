import { Container, Text, Flex, Image, Box } from "@chakra-ui/react";
import "./InThePressSection.css";
import PressCard from "./PressCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar, A11y } from "swiper/modules";
import { ArrowForwardIcon } from "@chakra-ui/icons";
// import Carousel from "react-elastic-carousel";

const InThePressSection = () => {
  return (
    <Container maxW="1550px" w="full" mx="auto" pb="20" pt="4">
      <Text
        as="h2"
        textAlign="center"
        fontSize={{ base: "25px", md: "40px" }}
        fontWeight="600"
        mb="8"
        className="font-fragment"
      >
        In The Press
      </Text>

      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        modules={[Navigation, Scrollbar, A11y]}
        navigation
        breakpoints={{
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        }}
        style={{ padding: "0px 30px 45px 30px" }}
      >
        <SwiperSlide style={{ height: "auto" }}>
          <Box
            // flex="1"
            bg="#02b39b"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
            h="100%"
          >
            <Text
              color="white"
              fontSize="18"
              fontWeight="500"
              mb="14"
              className="font-fragment"
            >
              " With the funding in place and after several months in private
              beta, Pomelo is officially launching with its first corridor to
              the Philippines. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="/img/section-08-img-1.png"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="white">
                    Pomelo raises $20m to combine credit and international money
                    transfers
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="white">
                    Finextra
                  </Text>
                </Box>
              </Flex>
              {/* <ArrowForwardIcon color="white" fontSize="28px" /> */}
            </Flex>
          </Box>
        </SwiperSlide>
        <SwiperSlide style={{ height: "auto" }}>
          <Box
            flex="1"
            bg="white"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
            color="black"
          >
            <Text
              color="#9ea3ae"
              fontSize="18"
              fontWeight="500"
              mb="14"
              className="font-fragment"
            >
              " Pomelo has raised a $20 million seed round led by Keith Rabois
              at Founders Fund and Kevin Hartz at A* Capital, with participation
              from Afore Capital, Xfund, Josh Buckley and The Chainsmokers. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSIzMiI+PHBhdGggZD0iTTAgMFYxMC42NzRoMTAuNjZ2MjEuMzMzaDEwLjY3NFYxMC42NzRoMTAuNjZWMHpNNDIuNjY3IDIyLjMzNFYxMC42NzRIMzEuOTkzdjIxLjMzM0g2NFYyMi4zMzR6bTAtMjIuMzM0SDY0VjEwLjY3NEg0Mi42Njd6IiBmaWxsPSIjMGE5NjAwIi8+PC9zdmc+"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="#9ea3ae">
                    Pomelo exits stealth mode with $20M seed to rethink
                    international money transfer
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="#9ea3ae">
                    TechCrunch
                  </Text>
                </Box>
              </Flex>
              {/* <ArrowForwardIcon color="white" fontSize="28px" /> */}
            </Flex>
          </Box>
        </SwiperSlide>
        <SwiperSlide style={{ height: "auto" }}>
          <Box
            flex="1"
            bg="#f0477a"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
            h="100%"
          >
            <Text
              color="white"
              fontSize="18"
              fontWeight="500"
              mb="14"
              className="font-fragment"
            >
              " Frenkiel came up with the idea when visiting his wife’s family
              in the Philippines. “Why can’t I just leave a card with my family
              instead of sending cash every month?” Frenkiel recalled. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="https://www.pomelo.com/static/Fox-5-Las-Vegas-Logo-8692d6ea441a97149b874286dee0f5dd.png"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="white">
                    Tech entrepreneur, recent Henderson transplant launching new
                    venture
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="white">
                    Fox 5
                  </Text>
                </Box>
              </Flex>
              {/* <ArrowForwardIcon color="white" fontSize="28px" /> */}
            </Flex>
          </Box>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Box
            flex="1"
            bg="#02b39b"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
          >
            <Text color="white" fontSize="18" fontWeight="500" mb="14">
              " With the funding in place and after several months in private
              beta, Pomelo is officially launching with its first corridor to
              the Philippines. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="/img/section-08-img-1.png"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="white">
                    Pomelo raises $20m to combine credit and international money
                    transfers
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="white">
                    Finextra
                  </Text>
                </Box>
              </Flex>
              <ArrowForwardIcon color="white" fontSize="28px" />
            </Flex>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            flex="1"
            bg="black"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
          >
            <Text color="white" fontSize="18" fontWeight="500" mb="14">
              " With the funding in place and after several months in private
              beta, Pomelo is officially launching with its first corridor to
              the Philippines. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="/img/section-08-img-1.png"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="white">
                    Pomelo raises $20m to combine credit and international money
                    transfers
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="white">
                    Finextra
                  </Text>
                </Box>
              </Flex>
              <ArrowForwardIcon color="white" fontSize="28px" />
            </Flex>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            flex="1"
            bg="#f0477a"
            py="10"
            px="6"
            rounded="2xl"
            boxShadow="2xl"
            fontFamily={"PP Neue Montreal"}
          >
            <Text color="white" fontSize="18" fontWeight="500" mb="14">
              " With the funding in place and after several months in private
              beta, Pomelo is officially launching with its first corridor to
              the Philippines. "
            </Text>
            <Flex justifyContent="space-between" alignItems="center" gap="3">
              <Flex alignItems="center" gap="3">
                <Image
                  src="/img/section-08-img-1.png"
                  maxW="80px"
                  w="full"
                  rounded="lg"
                />
                <Box>
                  <Text fontSize="13px" fontWeight="300" mb="5" color="white">
                    Pomelo raises $20m to combine credit and international money
                    transfers
                  </Text>
                  <Text fontSize="15px" fontWeight="500" color="white">
                    Finextra
                  </Text>
                </Box>
              </Flex>
              <ArrowForwardIcon color="white" fontSize="28px" />
            </Flex>
          </Box>
        </SwiperSlide> */}
      </Swiper>
    </Container>
  );
};

export default InThePressSection;

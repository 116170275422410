import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
} from "@chakra-ui/react";

export const Faqs = () => {
  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              fontWeight={"600"}
              fontSize={"17px"}
              flex="1"
              textAlign="left"
            >
              Who is funding this reward?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Snapsmile has access to our own SMILES fund that provides our users
          with rewards for taking care of their oral health
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              fontWeight={"600"}
              fontSize={"17px"}
              flex="1"
              textAlign="left"
            >
              Am I eligible for this reward?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          To be eligible for the reward, you just need to complete a new patient
          exam, cleaning, and full x-rays. If you're uninsured, this service
          costs anywhere between $500 and $650. If you're insured, you can call
          your insurer to learn if you're covered for a new patient exam and a
          dental cleaning.
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              fontWeight={"600"}
              fontSize={"17px"}
              flex="1"
              textAlign="left"
            >
              Who can I contact to learn more about this?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          You can email hello@snapsmile.ai to learn more about this offer
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              flex="1"
              fontSize={"17px"}
              fontWeight={"600"}
              textAlign="left"
            >
              Can I call a dental clinic to learn more about this?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          The dentist won't know what offers we're running, so it's best you
          reach out to us at hello@snapsmile.ai to learn more
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              flex="1"
              fontWeight={"600"}
              fontSize={"17px"}
              textAlign="left"
            >
              How does Snapsmile choose dental clinics to work with?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Snapsmile verifies each dental clinic we work with to ensure that they
          follow a high level of transparency, unbias service and a great
          patient experience
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              flex="1"
              fontWeight={"600"}
              fontSize={"17px"}
              textAlign="left"
            >
              Why is Snapsmile providing this reward?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          Our co-founding team started snapsmile because we grew up without
          dental coverage, making it almost impossible for us to get dental
          care. To eliminate the initial barrier of learning about your oral
          health with a real dentist, we're providing rewards to help people
          maintain a healthy smile
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton h="50px">
            <Box
              as="span"
              flex="1"
              fontWeight={"600"}
              fontSize={"17px"}
              textAlign="left"
            >
              What rewards can I claim?
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          We have an option for a VISA, Amazon, Nike, BestBuy gift cards, and
          for a limited time, we're providing Apple AirPods ($200 Value)
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

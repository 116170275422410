import { Dispatch, SetStateAction, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  Text
} from "@chakra-ui/react";
import { Admin, loginCreds } from "../../../types";
import { loginApi } from "../../../config/apis";
import { useNavigate, Navigate } from "react-router-dom";

export const Login = ({
  setUser
}: {
  setUser: Dispatch<SetStateAction<Admin | null>>;
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const gray = useColorModeValue("gray.50", "gray.800");
  const white = useColorModeValue("white", "gray.700");
  const loginUser = async (params: loginCreds) => {
    setIsLoading(true);
    setError("");
    try {
      const response = await loginApi(params);
      if (response.status != 200) {
        throw new Error(response.message);
      } else {
        console.log(response);
        const user: Admin = response.user;
        delete user["id"];
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        navigate("/admin/dashboard");
      }
    } catch (error: any) {
      setError(error.message || "");
    }
    setIsLoading(false);
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={gray}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box rounded={"lg"} bg={white} boxShadow={"lg"} p={8}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              loginUser({
                username: e.currentTarget.username.value,
                password: e.currentTarget.password.value
              });
            }}
          >
            <Stack spacing={4}>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input type="text" name="username" />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type="password" name="password" />
              </FormControl>
              {error && <Text color="red">{error}</Text>}

              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                ></Stack>
                <Button
                  type="submit"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500"
                  }}
                  isLoading={isLoading}
                >
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

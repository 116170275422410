import { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue,
  Heading
} from "@chakra-ui/react";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontWeight={"bold"}
      transform={"uppercase"}
      fontSize={"lg"}
      mb={2}
      color={"#01688b"}
      className="font-mantblack"
      style={{
        fontFamily: "Montblancbold"
      }}
    >
      {children}
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200")
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  function getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      {/* <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>SOLUTIONS</ListHeader>
            <Box as="a" href={"#"}>
              TRT
            </Box>
            <Box as="a" href={"#"}>
              Peptide Therapy
            </Box>
            <Box as="a" href={"#"}>
              Longevity Medicine
            </Box>
            <Box as="a" href={"#"}>
              Insulin Resistance
            </Box>
            <Box as="a" href={"#"}>
              Erectile Dysfunction (ED)
            </Box>
            <Box as="a" href={"#"}>
              Epigenetic Testing
            </Box>
            <Box as="a" href={"#"}>
              Hair Loss
            </Box>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>COMPANY</ListHeader>
            <Box as="a" href={"#"}>
              Contact us
            </Box>
            <Box as="a" href={"#"}>
              Memberships
            </Box>
            <Box as="a" href={"#"}>
              Military/First Responder
            </Box>
            <Box as="a" href={"#"}>
              Meet the Team
            </Box>
            <Box as="a" href={"#"}>
              About Us
            </Box>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>RESOURCES</ListHeader>
            <Box as="a" href={"#"}>
              Blog
            </Box>
            <Box as="a" href={"#"}>
              FAQ
            </Box>
            <Box as="a" href={"#"}>
              Privacy Policy
            </Box>
            <Box as="a" href={"#"}>
              Terms of Use
            </Box>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>CONTACT</ListHeader>
            <Box as="a" href={"#"}>
              (855) 443-8678 595 Pacific Ave, Floor 4 San Francisco, CA 94133
            </Box>
          </Stack>
        </SimpleGrid>
      </Container> */}

      <Box borderStyle={"solid"} bgColor={"#01688b"}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ md: "center" }}
          align={{ md: "center" }}
        >
          <Text color={"white"} textAlign={"center"}>
            © {getCurrentYear()} Opt Health
          </Text>
          {/* <Stack direction={"row"} spacing={6}>
            <SocialButton label={"Twitter"} href={"#"}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={"YouTube"} href={"#"}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={"Instagram"} href={"#"}>
              <FaInstagram />
            </SocialButton>
          </Stack> */}
        </Container>
      </Box>
    </Box>
  );
}

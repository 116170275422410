import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface ScrollTextProps {
  text: string;
  speed: number;
}

const marqueeVariants = {
  animate: {
    x: [0, -1035],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 10,
        ease: "linear",
      },
    },
  },
};

const ScrollText = ({ text, speed }: ScrollTextProps) => {
  return (
    <Box
      overflowX="hidden"
      display="flex"
      style={{
        overflow: "hidden",
      }}
    >
      <motion.div
        className="track"
        variants={marqueeVariants}
        animate="animate"
        style={{
          position: "relative",
          zIndex: 100,
        }}
      >
        {[...Array(5)].map((_, index) => (
          <Heading
            key={index}
            textTransform="uppercase"
            display="inline-block"
            className="font-mantblack"
            style={{
              fontFamily: "Montblancbold",
              zIndex: 100,
            }}
            px={4}
            fontSize={{ base: "4xl", md: "6xl" }}
            color={"#c9dce2"}
          >
            {text}
          </Heading>
        ))}
      </motion.div>
    </Box>
  );
};

export default ScrollText;

import { ReactComponentElement } from "react";
import { Kh } from "../../containers/kh";
import { User } from "../../types";
import { KhTermsPage } from "../../containers/kh/components/terms";
import { Ss } from "../../containers/ss";
import { SsTermsPage } from "../../containers/ss/components/terms";
import { Pm } from "../../containers/pm";
import { Oh } from "../../containers/oh";
import { OhTerms } from "../../containers/oh/components/terms";
import { PmTerms } from "../../containers/pm/Terms";
import { Lyka } from "../../containers/lyka";
import { LykaTerms } from "../../containers/lyka/components/Terms";

export const ALLOWED_LANDING_PAGE_HOSTS: {
  [key: string]: (props: { user: User }) => JSX.Element;
} = {
  "localhost:3001": (props) => <Oh {...props} />,
  "www.getopt.refer.us": (props) => <Oh {...props} />,
  "localhost:3002": (props) => <Pm {...props} />,
  "localhost:3004": (props) => <Ss {...props} />,
  "localhost:3003": (props) => <Lyka {...props} />,
  "192.168.0.141:3003": (props) => <Lyka {...props} />,
  "192.168.0.141:3002": (props) => <Pm {...props} />
};
export const ALLOWED_TERMS_PAGE_HOSTS: {
  [key: string]: () => JSX.Element;
} = {
  "localhost:3001": () => <OhTerms />,
  "www.getopt.refer.us": () => <OhTerms />,
  "localhost:3002": () => <PmTerms />,
  "localhost:3003": () => <LykaTerms />
};
export const eventCategories = {
  getStarted: "get_started_button_click",
  formSubmit: "form_submission_button_click",
  paymentPage: "payment_page_visit"
};

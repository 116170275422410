import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  List,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import "../index.css";
import "./Section03.css";
const IngredientItem = ({
  text,
  boxColor
}: {
  text: string;
  boxColor: string;
}) => {
  return (
    <Box h="40px" w="100%">
      <Box display={"flex"} flexDir={"row"} w="100%" alignItems={"center"}>
        <Box w="20%">
          <Box bg={boxColor} rounded="5px" minW="4" maxW={"4"} h="4" />
        </Box>
        <Text
          // as="span"
          fontSize="12px"
          color="#005648"
          lineHeight="1"
          ml={"10px"}
          // whiteSpace={"nowrap"}
          width={["100%", "80%"]}
          className="font-ginger"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
};
const Section03 = () => {
  return (
    <Container maxW={{ base: "97%", sm: "95%", xl: "1200px" }}>
      <Flex
        alignItems="center"
        columnGap="28"
        rowGap="16"
        flexDir={{ base: "column", xl: "row" }}
      >
        <Box flex="1">
          <Text
            as="h1"
            color="#005648"
            fontSize={{ base: "2.25rem", lg: "4rem" }}
            fontWeight="400"
            className="font-balgin"
            lineHeight="1.1"
            textAlign={{ base: "center", xl: "left" }}
          >
            A{" "}
            <Text as="span" color="#10b193">
              flexible meal plan{" "}
            </Text>
            personalised for{" "}
            <Text as="span" color="#10b193">
              your dog,
            </Text>{" "}
            delivered.
          </Text>
          <List flex="1" my={"20"} display={"flex"} flexDir={"column"} gap={5}>
            <ListItem display="flex" alignItems="center" gap="2">
              <Image src="/img/lyka-check.svg" w="14px" />
              <Text className="font-ginger" as="span" color="#005648">
                Formulated by board-certified veterinary nutritionists
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center" gap="2">
              <Image src="/img/lyka-check.svg" w="14px" />
              <Text as="span" className="font-ginger" color="#005648">
                Gently cooked at 90°C to retain nutrients
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center" gap="2">
              <Image src="/img/lyka-check.svg" w="14px" />
              <Text as="span" className="font-ginger" color="#005648">
                Ethically and sustainably sourced produce
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center" gap="2">
              <Image src="/img/lyka-check.svg" w="14px" />
              <Text as="span" className="font-ginger" color="#005648">
                Promote thriving health in dogs of all life stages
              </Text>
            </ListItem>
          </List>
          {/* <Text
            color="#005648"
            fontSize={{ base: "14px", sm: "16px", lg: "19px" }}
            mt="8"
            mb="5"
            textAlign={{ base: "center", xl: "left" }}
          >
            Australia is world famous for its fresh produce. Lyka’s team of
            Board Certified Veterinary Nutritionists formulate meals from the
            best local ingredients. Our meals are designed for maximum nutrient
            absorption; it’s what we call temperature enhanced nutrition. It’s
            the best ingredients lightly cooked for that tasty, real food
            goodness that dogs can’t get enough of.
          </Text> */}
          {/* <Button
            bg="#ff886b"
            rounded="full"
            color="white"
            px="7"
            py="6"
            fontWeight="400"
            fontSize="16px"
            mx={{ base: "auto", xl: "0" }}
            display="flex"
          >
            Learn More
          </Button> */}
        </Box>
        <Tabs
          align="center"
          flex="1"
          display={{ base: "none", xl: "block" }}
          className="font-ginger"
        >
          <TabPanels>
            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1em"
                >
                  Grass Fed Big Boppin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Beef Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-beef.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-beef-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="" boxColor="transparent" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  Bursting with amino acids and omega-3s for a healthy heart and
                  nourished skin.
                </Text>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1"
                >
                  Free Range Chow Chompin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Chicken Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-chicken.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-beef-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="" boxColor="transparent" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  Low GI and high in protein, it's great for the gut and gentle
                  on tummies.
                </Text>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1"
                >
                  Barn Raised Tail Tappin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Turkey Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-turkey.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-turkey-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  Rich in fermentable fibre, omega-3s and bioactive ingredients
                  for a healthy gut.
                </Text>
              </Box>
            </TabPanel>

            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1"
                >
                  Grass Fed Lip Lickin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Lamb Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-lamb.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-turkey-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  A calorie-dense recipe that's high in zinc and antioxidants
                  for healthy skin.
                </Text>
              </Box>
            </TabPanel>

            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1"
                >
                  Wild Caught Rip Rollickin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Kangaroo Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-kangaroo.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-beef-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="" boxColor="transparent" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  Hypoallergenic, ultra-low in fat and highly digestible, this
                  is a great all-rounder.
                </Text>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Text
                  as="h2"
                  textAlign="center"
                  fontSize="2.5rem"
                  fontWeight="400"
                  className="font-balgin"
                  color="#005648"
                  lineHeight="1.1"
                >
                  Wild Caught Fancy Fetchin' <br />{" "}
                  <Text as="span" color="#10b193">
                    Fish Bowl
                  </Text>
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box style={{ flex: "3" }}>
                    <Image src="/img/section-03-img-fish.webp" w="full" />
                  </Box>
                  <Box style={{ flex: "1" }}>
                    <Text color="#005648" fontSize="12px">
                      Ingredient breakdown
                    </Text>
                    <Image src="/img/section-03-img-beef-chart.webp" />
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="" boxColor="transparent" />
                  </Box>
                </Flex>
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                >
                  Filled with Omega-3s and 6s that boosts the brain and
                  digestive health.
                </Text>
              </Box>
            </TabPanel>
          </TabPanels>
          <TabList>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Beef
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Chicken
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Turkey
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Lamb
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Kangaroo
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Fish
            </Tab>
          </TabList>
        </Tabs>

        <Tabs
          align="center"
          flex="1"
          display={{ base: "block", xl: "none" }}
          className="font-ginger"
        >
          <TabPanels>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                // fontFamily="Balgin-bold"
                color="#005648"
                lineHeight="1.1"
                className="font-balgin"
              >
                Grass Fed Big Boppin' <br />{" "}
                <Text as="span" color="#10b193">
                  Beef Bowl
                </Text>
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                className="font-balgin"
                color="#005648"
                lineHeight="1.1"
              >
                Free Range Chow Chompin' <br />{" "}
                <Text as="span" color="#10b193">
                  Chicken Bowl
                </Text>
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                className="font-balgin"
                color="#005648"
                lineHeight="1.1"
              >
                Barn Raised Tail Tappin' <br />{" "}
                <Text as="span" color="#10b193">
                  Turkey Bowl
                </Text>
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                className="font-balgin"
                color="#005648"
                lineHeight="1.1"
              >
                Grass Fed Lip Lickin' <br />{" "}
                <Text as="span" color="#10b193">
                  Lamb Bowl
                </Text>
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                className="font-balgin"
                color="#005648"
                lineHeight="1.1"
              >
                Wild Caught Rip Rollickin' <br />{" "}
                <Text as="span" color="#10b193">
                  Kangaroo Bowl
                </Text>
              </Text>
            </TabPanel>
            <TabPanel>
              <Text
                as="h2"
                textAlign="center"
                fontSize="1.75rem"
                fontWeight="400"
                className="font-balgin"
                color="#005648"
                lineHeight="1.1"
              >
                Wild Caught Fancy Fetchin' <br />{" "}
                <Text as="span" color="#10b193">
                  Fish Bowl
                </Text>
              </Text>
            </TabPanel>
          </TabPanels>

          <TabList
            overflowX="scroll"
            maxW="90vw"
            overflowY="hidden"
            pl={{ base: "6.2rem", sm: "20", md: "0" }}
            sx={{
              scrollbarWidth: "none",
              borderBottomWidth: 0,
              "::-webkit-scrollbar": {
                display: "none"
              }
            }}
            // pr={20}
            pb={5}
          >
            <Tab
              _selected={{
                color: "#005648",
                borderColor: "#005648"
              }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Beef
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Chicken
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Turkey
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Lamb
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Kangaroo
            </Tab>
            <Tab
              _selected={{ color: "#005648", borderColor: "#005648" }}
              // fontWeight="600"
              fontSize="16px"
              color="#0056488f"
              borderColor="#0056488f"
            >
              Fish
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-beef.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  Bursting with amino acids and omega-3s for a healthy heart and
                  nourished skin.
                </Text>
                <Flex alignItems="center" gap="2" my="16" mt={"24px"}>
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    {/* <IngredientItem text="" boxColor="transparent" /> */}
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-chicken.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  Low GI and high in protein, it's great for the gut and gentle
                  on tummies.
                </Text>
                <Flex alignItems="center" gap="2" my="16" mt={"24px"}>
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    {/* <IngredientItem text="" boxColor="transparent" /> */}
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-turkey.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  Rich in fermentable fibre, omega-3s and bioactive ingredients
                  for a healthy gut.
                </Text>
                <Flex alignItems="center" gap="2" my="16" mt={"24px"}>
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" />
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-lamb.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  A calorie-dense recipe that's high in zinc and antioxidants
                  for healthy skin.
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" />
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-kangaroo.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  Hypoallergenic, ultra-low in fat and highly digestible, this
                  is a great all-rounder.
                </Text>
                <Flex alignItems="center" gap="2" my="16">
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    {/* <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" /> */}
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box flex="1">
                <Image
                  src="/img/section-03-img-fish.webp"
                  w="full"
                  maxW={{ base: "250px", md: "450px" }}
                />
                <Text
                  color="#005648"
                  fontSize="18px"
                  fontWeight="400"
                  textAlign="center"
                  lineHeight="1"
                  maxW="360px"
                  mx="auto"
                  mt={"8px"}
                >
                  Filled with Omega-3s and 6s that boosts the brain and
                  digestive health.
                </Text>
                <Flex alignItems="center" gap="2" my="16" mt={"24px"}>
                  <Box flex="1">
                    <Text color="#005648" fontSize="13px">
                      Ingredient breakdown
                    </Text>
                    <Image
                      src="/img/section-03-img-beef-chart.webp"
                      maxW="200px"
                    />
                  </Box>
                  <Box flex="1">
                    <IngredientItem
                      text="Human Grade Meat"
                      boxColor="#005648"
                    />

                    <IngredientItem
                      text="Fruits & Vegetables"
                      boxColor="#10b193"
                    />

                    <IngredientItem
                      text="Botanicals + Superfoods"
                      boxColor="#ff886b"
                    />
                    {/* <IngredientItem text="Ancient Grains" boxColor="#dbe6dc" /> */}
                  </Box>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Container>
  );
};

export default Section03;

import { Box, Image, Text } from "@chakra-ui/react";
import Quote from "../../assets/quote-active.png";
type Props = {
  invertedColors?: boolean;
  review: string;
  name: string;
  location: string;
};

export const ReviewCard = ({
  invertedColors,
  review,
  name,
  location
}: Props) => {
  return (
    <Box
      display={"flex"}
      bgColor={invertedColors ? "rgb(64, 123, 255)" : "white"}
      // p={10}
      gap={18}
      px={5}
      py={10}
      w={"fit-content"}
      borderRadius={10}
      height={"fit-content"}
      boxShadow={"rgba(0, 0, 0, 0.1) 0px 4px 10px"}
    >
      <Box w={"10%"}>
        <Box
          height={"40px"}
          width={"40px"}
          borderRadius={15}
          bgColor={invertedColors ? "white" : "rgb(64, 123, 255)"}
        >
          <Image src={Quote} borderRadius={15} />
        </Box>
      </Box>
      <Box
        width={"90%"}
        display={"flex"}
        flexDir={"column"}
        // justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Text
          fontSize={"18px"}
          w="100%"
          color={invertedColors ? "white" : "black"}
        >
          {review}
        </Text>
        <Box
          w={"100%"}
          mt={10}
          height={"2px"}
          bgColor={invertedColors ? "white" : "rgb(64, 123, 255)"}
        ></Box>
        <Box
          display={"flex"}
          flexDir={"row"}
          w="100%"
          alignItems={"center"}
          mt={5}
          gap={5}
        >
          <Box>
            <Box
              w="40px"
              h="40px"
              borderRadius={20}
              bgColor={invertedColors ? "white" : "rgb(64, 123, 255)"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              // mt={5}
            >
              <Text
                fontWeight={"bold"}
                color={invertedColors ? "rgb(64, 123, 255)" : "white"}
              >
                AN
              </Text>
            </Box>
          </Box>
          <Box color={invertedColors ? "white" : "black"} fontWeight={"bold"}>
            <Text>{name}</Text>
            <Text fontWeight={"normal"}>{location}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

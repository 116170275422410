import React from "react";
import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue
} from "@chakra-ui/react";

export default function CTA() {
  return (
    <Flex
      w={"full"}
      backgroundImage={
        "url(https://getopt.com/wp-content/uploads/2023/09/usa.webp)"
      }
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
      py={28}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"4xl"} align={"flex-start"} spacing={6}>
          <Stack direction={"column"} spacing={2}>
            <Text
              color={"white"}
              fontWeight={700}
              lineHeight={1.2}
              fontSize={{ base: "3xl", md: "4xl", lg: "60px" }}
              textAlign={"center"}
              className="font-mantblack"
              style={{
                fontFamily: "Montblancbold"
              }}
            >
              BORN IN THE USA
            </Text>
            <Text
              color={"white"}
              fontSize={{ base: "lg", md: "xl" }}
              textAlign={"center"}
              fontWeight={"semibold"}
            >
              All Opt Health prescriptions are manufactured in the USA at
              state-of-the-art, fully licensed compounding facilities with the
              strictest commitment to quality assurance.
            </Text>
          </Stack>
          <Stack
            width={"full"}
            direction={"row"}
            justify={"center"}
            align={"center"}
          >
            {/* <Button
              bg={"blue.400"}
              rounded={"full"}
              color={"white"}
              _hover={{ bg: "blue.500" }}
            >
              Get Started
            </Button> */}
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
}

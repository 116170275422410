import {
  Avatar,
  Box,
  Heading,
  Stack,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import testinomialData from "../../data/testinomial.data";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 }
};

interface CardProps {
  name: string;
  description: string;
  imageSrc: string;
}

function Card({ name, description, imageSrc }: CardProps) {
  return (
    <Box
      maxW={"320px"}
      w={"full"}
      bg={useColorModeValue("white", "gray.900")}
      rounded={"lg"}
      p={6}
      border={1}
      borderStyle={"solid"}
      borderColor={"black"}
      textAlign={"center"}
    >
      <Avatar size={"xl"} src={imageSrc} mb={4} pos={"relative"} />
      <Text
        textAlign={"center"}
        color={useColorModeValue("gray.700", "gray.400")}
        px={3}
      >
        {description}
      </Text>
      <Heading
        fontSize={"xl"}
        mt={4}
        color={"#0b3b3c"}
        className="font-mantblack"
        style={{
          fontFamily: "Montblancbold"
        }}
      >
        {name}
      </Heading>
    </Box>
  );
}

export default function Testinomial() {
  return (
    <Box py={10} position="relative">
      {/* Absoloute background color div */}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="60%"
        backgroundColor="#ccdbe0"
        zIndex={-1}
      />

      <Box px={{ base: 10, sm: 10, md: 0 }} maxW={"6xl"} mx={"auto"}>
        <VStack spacing={4} textAlign="start" mb={20}>
          <Heading
            as="h2"
            fontSize={{ base: "4xl", md: "6xl" }}
            maxW={"2xl"}
            mr={"auto"}
          >
            <Text
              color={"#01688b"}
              as={"span"}
              className="font-mantblack"
              style={{
                fontFamily: "Montblancbold"
              }}
            >
              JOIN THE NEXT COHORT OF OPT MEN!
            </Text>
          </Heading>
          <Text
            fontSize="xl"
            fontWeight={"semibold"}
            color={"#01688b"}
            maxW={"3xl"}
            mr={"auto"}
          >
            {`Hear from the men that changed their lives through the power of Opt Health. From former Navy SEALs to your everyday man, their progress is inspiring and transformational.`}
          </Text>
        </VStack>
        <Stack style={{ cursor: "grab" }}>
          <AliceCarousel
            disableButtonsControls
            autoPlay
            autoPlayInterval={1000}
            animationDuration={1000}
            animationType="fadeout"
            mouseTracking
            infinite
            responsive={responsive}
            controlsStrategy="alternate"
          >
            {testinomialData.map((item, i) => (
              <div key={i} data-value={i}>
                <Card
                  name={item.name}
                  description={item.description}
                  imageSrc={item.imageSrc}
                />
              </div>
            ))}
          </AliceCarousel>
        </Stack>
      </Box>
    </Box>
  );
}

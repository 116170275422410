import { useEffect, useState } from "react";
import { getCustomers } from "../../../config/apis";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const columns = ["Name", "Domain", "Lead Count"];
type customer = {
  name: string;
  domain: string;
  _count: { customerlead: number };
  id: number;
};
export const Customers = () => {
  const [customers, setCustomers] = useState<customer[]>([]);
  const navigate = useNavigate();

  const _getCustomers = async () => {
    try {
      const response = await getCustomers();
      // console.log(response);

      setCustomers(response.customers);
    } catch (error) {}
  };
  useEffect(() => {
    _getCustomers();
  }, []);
  return (
    <Skeleton isLoaded={!!customers.length}>
      <TableContainer bgColor={"white"}>
        <Table variant="simple">
          {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
              {/* <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {customers && customers?.length ? (
              customers.map((customer: customer) => {
                return (
                  <Tr
                    key={customer.id}
                    onClick={() => {
                      navigate("/admin/customers/" + customer.id);
                    }}
                  >
                    <Td>{customer.name}</Td>
                    <Td>{customer.domain}</Td>
                    <Td>{customer._count.customerlead}</Td>
                  </Tr>
                );
              })
            ) : (
              <Tr></Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  );
};

import { Navigate, Outlet } from "react-router-dom";
import { Admin } from "../../types";
type Props = {
  user: Admin | null;
  redirectPath?: string;
};

export const ProtectedRoute = ({
  user,
  redirectPath = "/admin/login"
}: Props) => {
  if (
    window.location.host == "www.admin.refer.us" ||
    window.location.host == "localhost:3005"
  ) {
    if (!user) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
  }

  return <Navigate to={"/error"} replace />;
};

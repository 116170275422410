import React, { useEffect, useState } from "react";
import Hero from "./components/homepage/hero";
import GetBackOnTrackSection from "./components/homepage/get-back-on-track";
import DesignedForYourSuccessSection from "./components/homepage/designed-for-your-success";
import Team from "./components/homepage/team";
import Pricing from "./components/homepage/pricing";
import CTA from "./components/homepage/cta";
import BaseLayout from "./components/layout";
import Testinomial from "./components/homepage/testinomial";
import ScrollText from "./components/homepage/scroll-text";
import {
  Box,
  Image,
  SimpleGrid,
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  Skeleton
} from "@chakra-ui/react";
import "./index.css";
import { User, formDataType } from "../../types";
import SignUpPage from "./pages/signuppage";
import { isValidNumber } from "libphonenumber-js";
import { postData } from "../../config/apis";
import { create, getUserProfile, login, sync } from "../../config/apis/oh";
import SubscriptionPage from "./pages/subscriptionpage";

interface Props {
  user: User;
  children?: React.ReactNode;
}
type newUser = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  id: number;
};
export const Oh: React.FC<Props> = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [showPlanPage, setShowPlanPage] = useState(false);
  const [newUser, setNewUser] = useState<newUser>({} as newUser);
  const handleSubmit = async (
    params: formDataType,
    type: "login" | "signup"
  ) => {
    setError("");
    if (type === "signup") {
      if (isValidNumber(params.phone, "US")) {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(
            params.password || ""
          )
        ) {
          setIsLoading(true);
          try {
            await postData({
              ...params,
              internal: true,
              external: false,
              sendEmail: false
            });
            const optUser = await create(
              params as formDataType & { gender: string; state: string }
            );
            if (optUser.status === 200) {
              sync({
                leadName: params.firstName + " " + params.lastName,
                leadEmail: params.email,
                referrerName: user.full_name,
                referrerEmail: user.email
              });
              setNewUser({
                first_name: params.firstName,
                last_name: params.lastName,
                email: params.email,
                phone: params.phone,
                id: optUser.id
              });
              window.localStorage.setItem("token", optUser.token);
              // console.log(optUser);
              setShowPaymentPage(true);
            } else if (optUser.status === 400) {
              try {
                const loginResponse = await login({
                  email: params.email,
                  password: params.password as string
                });
                if (
                  loginResponse.me.status === "signup-complete" ||
                  !loginResponse.me.userExamPlan
                ) {
                  setNewUser({
                    first_name: params.firstName,
                    last_name: params.lastName,
                    email: params.email,
                    phone: params.phone,
                    id: optUser.id
                  });
                  sync({
                    leadName: params.firstName + " " + params.lastName,
                    leadEmail: params.email,
                    referrerName: user.full_name,
                    referrerEmail: user.email
                  });
                  window.localStorage.setItem("ohEmail", params.email);
                  window.localStorage.setItem("token", loginResponse.token);

                  setShowPaymentPage(true);
                } else {
                  setError(
                    "Looks like you are already an Opt Health member! You can log in to your dashboard here."
                  );
                }
                setIsLoading(false);
              } catch (error) {
                setError(
                  "Looks like you already have an account. Please login"
                );
              }
            }
            setIsLoading(false);
          } catch (error) {
            setError("Something went wrong");
            setIsLoading(false);
          }
        } else {
          setError(
            "Passwords must be 8+ characters, 1+ uppercase, 1+ lowercase, 1+ numeric."
          );
        }
      } else {
        setError("Invalid phone number");
      }
    } else if (type === "login") {
      try {
        setIsLoading(true);
        const loginResponse = await login({
          email: params.email,
          password: params.password as string
        });
        await postData({
          email: params.email,
          password: params.password,
          firstName: loginResponse.me.firstName,
          lastName: loginResponse.me.lastName,
          fullName:
            loginResponse.me.firstName + " " + loginResponse.me.lastName,
          referredBy: user.id,
          phone: loginResponse.me.phone,
          internal: true,
          external: false,
          sendEmail: false
        });
        if (
          loginResponse.me.status === "signup-complete" ||
          !loginResponse.me.userExamPlan
        ) {
          sync({
            leadName:
              loginResponse.me.firstName + " " + loginResponse.me.lastName,
            leadEmail: params.email,
            referrerName: user.full_name,
            referrerEmail: user.email
          });

          setNewUser({
            first_name: loginResponse.me.first_name,
            last_name: loginResponse.me.last_name,
            email: params.email,
            phone: loginResponse.me.phone,
            id: loginResponse.me.id
          });
          window.localStorage.setItem("token", loginResponse.token);
          window.localStorage.setItem("ohEmail", params.email);

          setShowPaymentPage(true);
        } else {
          setError(
            "Looks like you are already an Opt Health member! You can log in to your dashboard here."
          );
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        setError("Email or password is invalid");
      }
    }
  };
  const getProfile = async () => {
    try {
      const profile = await getUserProfile();
      if (profile.status === 401) {
        return;
      }
      window.localStorage.setItem("ohEmail", profile.email);
      setNewUser({
        first_name: profile.firstName,
        last_name: profile.lastName,
        email: profile.email,
        id: profile.id,
        phone: profile.phone
      });
      setShowPaymentPage(true);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      getProfile();
    }
  }, []);
  return (
    <Skeleton isLoaded={!!user.full_name}>
      <BaseLayout>
        <Hero
          normallabel=""
          colorlabel={`Accept your VIP Invite From ${
            user?.full_name?.split?.(" ")?.[0] || ""
          }`}
          subLabel={`As an Opt Health member referral, you get a complimentary initial lab covering 60+ biomarkers`}
          btnLabel="Accept Invitation"
          imageSrc="https://getopt.com/wp-content/uploads/2023/09/543.webp"
          btnClick={() => {
            window.location.replace("https://getopt.com/");
          }}
        />
        <SimpleGrid
          columns={{ base: 2, md: 4 }}
          spacing={10}
          mt={{ base: 10, md: 20 }}
          maxW={"6xl"}
          px={6}
          mx={"auto"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={5}
        >
          <Image
            alt={"company"}
            maxW={150}
            src={"https://getopt.com/wp-content/uploads/2022/07/CNN-1.png"}
            objectFit={"cover"}
          />
          <Image
            alt={"company"}
            maxW={150}
            src={
              "https://getopt.com/wp-content/uploads/2022/08/Mens_Health-1.png"
            }
            objectFit={"cover"}
          />
          <Image
            alt={"company"}
            maxW={150}
            src={"https://getopt.com/wp-content/uploads/2022/07/Asset-1-1.png"}
            objectFit={"cover"}
          />
          <Image
            alt={"company"}
            maxW={150}
            src={"https://getopt.com/wp-content/uploads/2022/07/Asset-2-1.png"}
            objectFit={"cover"}
          />
        </SimpleGrid>
        <GetBackOnTrackSection />
        <Box
          py={10}
          overflowX="hidden"
          style={{
            overflowX: "hidden",
            overflow: "hidden"
          }}
        >
          <Box
            px={0}
            mx="auto"
            style={{
              overflow: "hidden",
              width: "max-content"
            }}
            overflow="hidden"
          >
            <ScrollText
              text="Your health optimization journey Your health optimization journey"
              speed={10}
            />
          </Box>
        </Box>
        <Hero
          normallabel="It starts with professional"
          colorlabel="consultation"
          subLabel="Health optimization is achieved through a combination of blood work to understand your health, a personalized treatment plan to get you feeling your best, and 1:1 physician consultations to keep you on track."
          btnLabel="Accept Invitation"
          imageSrc="https://getopt.com/wp-content/uploads/2022/08/mobile.png"
          isAppImage
          btnClick={() => {
            window.location.replace("https://getopt.com/");
          }}
        />
        <DesignedForYourSuccessSection />
        <Testinomial />
        <Team />
        <Box
          py={10}
          overflowX="hidden"
          style={{
            overflowX: "hidden",
            overflow: "hidden"
          }}
        >
          <Box
            px={0}
            mx="auto"
            style={{
              overflow: "hidden",
              width: "max-content"
            }}
            overflow="hidden"
          >
            <ScrollText
              text="MORE ENERGY IMPROVED SLEEP SHARPENED FOCUS INCREASED LIBIDO"
              speed={10}
            />
          </Box>
        </Box>
        <Pricing
          userText={`$0 with ${
            user?.full_name?.split?.(" ")?.[0] || ""
          }'s invitation`}
        />
        <CTA />
      </BaseLayout>
    </Skeleton>
  );
};

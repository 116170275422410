import { Container, Box, Image, Flex, Text } from "@chakra-ui/react";
import "./AddSection.css";
import { motion } from "framer-motion";

const AddSection = () => {
  return (
    <Container maxW="5xl" w="full" mx="auto" pb="20">
      <Flex
        gap={{ base: "5", md: "10", lg: "20" }}
        alignItems="center"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box flex="1" pos="relative">
          <Image
            src="/img/section-05-img-1.webp"
            pos="relative"
            zIndex="99999"
            display={{ base: "none", md: "initial" }}
          />
          <Image
            src="/img/section-05-img-1-mobile.png"
            mx={{ base: "auto", md: "0" }}
            display={{ base: "initial", md: "none" }}
            maxW="200"
          />
          <Box pos="absolute" top="0" left="0" zIndex="9999">
            <Box pos="relative" display={{ base: "none", md: "flex" }}>
              <motion.img
                src="/img/section-05-img-2.svg"
                animate={{ x: 140, y: 110, rotate: 12 }}
                style={{ position: "relative", left: "0", zIndex: "999" }}
              />
              <motion.img
                src="/img/section-05-img-3.svg"
                animate={{ x: 150, y: 90, rotate: 20 }}
                style={{
                  position: "relative",
                  left: "0",
                  marginLeft: "-140px",
                  zIndex: "99"
                }}
              />
              <motion.img
                src="/img/section-05-img-4.svg"
                animate={{ x: 160, y: 75, rotate: 30 }}
                style={{
                  position: "relative",
                  left: "0",
                  marginLeft: "-140px",
                  zIndex: "9"
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          flex="1"
          textAlign={{ base: "center", md: "left" }}
          className="font-fragment"
        >
          <Text as="h2" fontSize={{ base: "2xl", md: "4xl" }} fontWeight="600">
            Pomelo Card +
          </Text>
          <Text as="h2" fontSize={{ base: "2xl", md: "4xl" }} fontWeight="600">
            Pomelo App =
          </Text>
          <Text
            as="h2"
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="600"
            color="#f0477a"
          >
            Pomelo Power
          </Text>
          <Text
            fontSize="sm"
            color="#190e34"
            mt="8"
            fontFamily={"PP Neue Montreal"}
          >
            We've reinvented money transfer using the power of credit. Apply now
            to send a Pomelo Mastercard™ to anyone, anywhere in the Philippines.
            They can spend it anytime, anywhere Mastercard is accepted. Send
            now, pay later, zero APR.
          </Text>
          <Text
            fontSize="sm"
            color="#190e34"
            my="8"
            fontFamily={"PP Neue Montreal"}
          >
            With the Pomelo App, you have full control. Decide how much credit
            to share with each person. Pause at any time. Monitor every
            transaction. Pay it once a month, even put it on autopay.
          </Text>
          <Flex alignItems="center" gap="5">
            <Image src="/img/section-05-img-5.svg" />
            <Image src="/img/section-05-img-6.svg" />
            <Image src="/img/section-05-img-7.svg" />
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default AddSection;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Image,
  Text
} from "@chakra-ui/react";
import "../index.css";

const Section06 = () => {
  return (
    <Box bg="#005648" rounded="24px" maxW="98%" mx="auto" mt={10}>
      <Container
        maxW={{ base: "95%", xl: "1440px" }}
        py={{ base: "40px", xl: "110px" }}
      >
        <Box display={"flex"} flexDir={"column"} alignItems={"center"}>
          <Image src="img/dogs.svg" boxSize={"2xl"} h={["auto", "400px"]} />
          <Text
            color={"white"}
            fontSize={["50px", "63px"]}
            className="font-balgin"
          >
            How it{" "}
            <Text as="span" color="#10b193">
              works
            </Text>
          </Text>
        </Box>
        <Flex
          flexDir={["column", "row"]}
          gap={2}
          flex={1}
          // alignItems={"center"}
          mt={10}
        >
          <Box
            display={"flex"}
            flexDir={"column"}
            flex={1}
            alignItems={"center"}
            gap={5}
            color={"white"}
          >
            <Image src="img/step1.svg" w="16" />
            <Text
              fontSize={"30px"}
              textAlign={"center"}
              color={"white"}
              className="font-balgin"
              lineHeight={"1.1em"}
            >
              Choose a plan that suits you
            </Text>
            <Text
              fontSize={["16px", "20px"]}
              textAlign={"center"}
              className="font-ginger"
            >
              We’ll tailor you a custom meal plan. Choose between recipe mixes
              and portion sizes based on your pupper’s unique nutrient needs.
            </Text>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            flex={1}
            alignItems={"center"}
            gap={5}
            color={"white"}
            mt={[10, 0]}
          >
            <Image src="img/step2.svg" w="16" />
            <Text
              fontSize={"30px"}
              textAlign={"center"}
              color={"white"}
              className="font-balgin"
              lineHeight={"1.1em"}
            >
              Receive your free starter box
            </Text>
            <Text
              fontSize={["16px", "20px"]}
              textAlign={"center"}
              className="font-ginger"
            >
              Your pupper's meals are delivered direct to your door, free of
              charge.
            </Text>
          </Box>
          <Box
            display={"flex"}
            flexDir={"column"}
            flex={1}
            alignItems={"center"}
            gap={5}
            color={"white"}
            mt={[10, 0]}
          >
            <Image src="img/step3.svg" w="16" />
            <Text
              fontSize={"30px"}
              textAlign={"center"}
              color={"white"}
              className="font-balgin"
              lineHeight={"1.1em"}
            >
              Enjoy flexibility and control
            </Text>
            <Text
              fontSize={["16px", "20px"]}
              textAlign={"center"}
              className="font-ginger"
            >
              You’ll be notified ahead of your next delivery and billing cycle.
              Skip an order, delay, pause or cancel any time.
            </Text>
          </Box>
        </Flex>
        {/* <Flex
          alignItems="center"
          gap="14"
          flexDir={{ base: "column", xl: "row" }}
        >
          <Box flex="1">
            <Text
              as="h1"
              color="#fffaec"
              fontSize={{ base: "35px", xl: "48px" }}
              lineHeight="1"
              fontWeight="800"
            >
              <Text color="#10b193" as="span">
                96%{" "}
              </Text>
              of dogs see health improvements with Lyka.
            </Text>
            <Text
              color="#fffaec"
              fontSize={{ base: "15px", xl: "20px" }}
              my={{ base: "4", xl: "7" }}
            >
              After feeding thousands of dogs across Australia, here’s what
              we’ve heard from our Lyka community, and some of the benefits you
              and your pupper may start experiencing.
            </Text>
            <Text
              as="a"
              color="#10b193"
              fontSize={{ base: "15px", xl: "20px" }}
            >
              Read pupper success stories {">"}
            </Text>
          </Box>
          <Box flex="1" textAlign={{ base: "center", xl: "right" }}>
            <Image src="/img/faqs.svg" ml={{ base: "0", xl: "auto" }} />
          </Box>
        </Flex>

        <Accordion
          defaultIndex={[0]}
          allowToggle
          gap="5"
          display="flex"
          flexDir="column"
          mt="16"
        >
          <AccordionItem
            borderWidth="0px"
            rounded="3xl"
            py="5"
            px="4"
            bg="#126254"
          >
            <h2>
              <AccordionButton _hover={{ background: "none" }} color="#fffaec">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight="600"
                  fontSize="20px"
                >
                  Better digestion, less poo to pick up
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="#fffaec" fontSize="15" bg="#1b6659">
              Meals built for nutrient absorption means smaller and less
              offensive poo.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            borderWidth="0px"
            rounded="3xl"
            py="5"
            px="4"
            bg="#126254"
          >
            <h2>
              <AccordionButton _hover={{ background: "none" }} color="#fffaec">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight="600"
                  fontSize="20px"
                >
                  Softer and shinier coat, get all those compliments
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="#fffaec" fontSize="15" bg="#1b6659">
              Nutrient-dense meals nourish them on the inside and shine through
              to the outside. offensive poo.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            borderWidth="0px"
            rounded="3xl"
            py="5"
            px="4"
            bg="#126254"
          >
            <h2>
              <AccordionButton _hover={{ background: "none" }} color="#fffaec">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight="600"
                  fontSize="20px"
                >
                  Glowing skin, less itching and redness
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="#fffaec" fontSize="15" bg="#1b6659">
              "Omega 6:3 ratios exceed AAFCO standards by 8-15x", meaning more
              good fats for the skin to drink in.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            borderWidth="0px"
            rounded="3xl"
            py="5"
            px="4"
            bg="#126254"
          >
            <h2>
              <AccordionButton _hover={{ background: "none" }} color="#fffaec">
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight="600"
                  fontSize="20px"
                >
                  Maintain the perfect weight
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color="#fffaec" fontSize="15" bg="#1b6659">
              Feed the right amount every time with individually portioned
              meals, no guesswork required.
            </AccordionPanel>
          </AccordionItem>
        </Accordion> */}
      </Container>
    </Box>
  );
};

export default Section06;

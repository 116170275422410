import "./FaqSection.css";
import FaqCard from "./FaqCard";
import {
  Container,
  Text,
  Accordion,
  AccordionItem,
  Box,
  AccordionButton,
  AccordionIcon,
  AccordionPanel
} from "@chakra-ui/react";
const Faq = () => {
  return (
    <Container
      maxW="5xl"
      w="full"
      mx="auto"
      pb="20"
      pt="4"
      fontFamily={"PP Neue Montreal"}
    >
      <Text
        as="h2"
        textAlign="center"
        fontSize={{ base: "25px", md: "40px" }}
        fontWeight="600"
        mb="5"
        className="font-fragment"
      >
        Frequently Asked Questions
      </Text>
      <Accordion
        defaultIndex={[0]}
        allowToggle
        gap="5"
        display="flex"
        flexDir="column"
      >
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize="16"
                fontWeight="600"
              >
                Why do you ask for my SSN during signup?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} color="#3a4151" fontSize="15">
            Because Pomelo is the first to combine credit with money transfer,
            providing your SSN allows us to verify your identity and provide you
            a credit offer that helps you earn rewards and build your credit
            whenever you send money overseas. Your SSN is not stored or read by
            Pomelo, and only transmitted securely to an identity verification
            service for our bank partner, Coastal Community Bank, member FDIC,
            to confirm your identity.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                Will giving my SSN harm my credit score?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            No - viewing your credit offer does not affect your credit score.
            This is called a soft pull. For customers who accept and receive
            credit from Pomelo, we then perform a hard pull with the credit
            bureaus to formally open your credit line. This will then show up on
            your credit report, but on-time payments will typically help your
            credit score, not hurt it.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                I don't have good credit. Will Pomelo decline my application?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            If you don't have good credit today, you can still use the Pomelo
            Mastercard with Credit Builder, a secured card that is backed by
            your own funds, up to $500. After a sufficient number of positive
            payments, Pomelo may graduate you to an unsecured credit line and
            refund your original security deposit.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                How does Pomelo provide such amazing rates?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Glad you asked! First, we use Mastercard's real-time exchange rate
            for each transaction, so you never have to worry about timing a
            transfer for the best price. Second, when customers use their Pomelo
            Cards anywhere Visa or Mastercard is accepted, we earn a small
            percentage from the merchant - not from you! This means that all
            transaction fees are now subsidized by the places and venues where
            your recipients shop in store like pharmacies, supermarkets and
            online (merchants like Meralco, Grab, etc.) in the Philippines. How
            cool is that?
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                So, Pomelo is a credit card?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Pomelo is a type of credit card called a charge card, which means
            you must settle your balance in full every billing cycle. As a
            result, we do not charge interest - no APR at all - and we have a
            smaller maximum limit (up to $1,000), to ensure our customers never
            get trapped in debt. In fact, our only fee is a late fee and we
            allow our customers to use auto-pay to ensure you're never late. To
            get the Pomelo Mastercard, you will need to apply for it like you
            would any other credit card product.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                What does it mean to ‘Send now, Pay later?’
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            This means your loved ones in the Philippines can start spending the
            moment they get their Pomelo cards delivered. You don’t have to pay
            until you get your monthly bill. The bill is calculated using the
            Pomelo rate + any promotional rate you have.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                Ok, no transfer fees. But are there any other fees?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            There are no fees to use Pomelo as long as you keep your account in
            good standing, meaning you pay your bill on time. Customers have up
            to 25 days past each billing cycle to settle their balance. A $39
            late fee may apply on any missed payments, but don't worry - the app
            supports payment reminders and auto-pay so you never have to worry
            about a missed payment.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                How does Pomelo make money if you don’t charge fees?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Like most other money transfer companies, we earn from currency
            exchange. But we also earn from something called interchange. This
            is a fee that merchants in the Philippines pay us – not a fee you
            pay! This is why we are able to offer a better exchange rate.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                What is the fee for ATM withdrawals?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            At this moment we don’t allow ATM withdrawals or ATM cash advances.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                How does Pomelo value my safety?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Pomelo was designed from the ground-up to be safe. Imagine
            bank-level security for cashless money transfer. You can pause the
            card at any time. Your personal information is encrypted. Your loved
            ones don’t line up at pawnshops and walk around with lots of cash.
            Here’s the best part: you’re not going to worry where your money is.
            You pay the bill only after it arrives, after your loved ones have
            spent the money. You can also dispute anything that doesn’t look
            right just like a credit card. With traditional money transfers you
            cross your fingers and say a prayer every time your money leaves
            your bank account. We’ve fixed that with Pomelo.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                How do you handle disputes for unauthorized charges?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            On a case-by-case basis. We will review every dispute to ensure you
            are protected from valid unauthorized purchases. This is something
            cash-based money transfers simply cannot offer.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                What do you mean by money being “always available?”
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Transfer time doesn’t apply to us. It doesn’t take days, hours, or
            even minutes for money to arrive with Pomelo, because as long as
            your loved one has the Pomelo card and room in their credit limit,
            they can spend the money. So if there’s an emergency, you don’t have
            to hurry to send money as long as you have credit available.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          borderWidth="1px"
          borderColor="#d2d5db"
          rounded="xl"
          py="5"
          px="4"
        >
          <h2>
            <AccordionButton _hover={{ background: "none" }}>
              <Box
                fontSize="16"
                fontWeight="600"
                as="span"
                flex="1"
                textAlign="left"
              >
                What are the acceptable forms of ID for beneficiaries in the
                Philippines?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel color="#3a4151" fontSize="15" pb={4}>
            Here are some forms of ID you can submit for identity verification:
            <ul>
              <li>Passport</li>
              <li>UMID</li>
              <li>PhilHealth Card</li>
              <li>Philippine Postal ID</li>
              <li>Philsys National ID</li>
              <li>Driver's License</li>
              <li>Senior Citizen ID</li>
              <li>PWD ID</li>
              <li>TIN ID</li>
            </ul>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default Faq;

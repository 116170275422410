import React from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Flex,
  Image
} from "@chakra-ui/react";
import getbackontrackData from "../../data/getbackontrack.data";

interface FeatureProps {
  title: string;
  text: string;
  imageSrc: string;
}

const Feature = ({ title, text, imageSrc }: FeatureProps) => {
  return (
    <Stack flexDirection={"row"} spacing={5} mt={4}>
      <Image
        boxSize="60px"
        objectFit="cover"
        src={imageSrc}
        alt="Your Image Alt Text"
      />
      <Flex direction={"column"} color={"#01688b"}>
        <Heading
          fontSize={{ sm: "2xl", md: "3xl" }}
          fontWeight={"bold"}
          size="lg"
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
        >
          {title}
        </Heading>
        <Text mt={2}>{text}</Text>
      </Flex>
    </Stack>
  );
};

export default function GetBackOnTrackSection() {
  return (
    <Box py={10} maxW={"6xl"} mx={"auto"} px={5}>
      <VStack spacing={2} w={"full"}>
        <Heading
          as="h2"
          fontSize={{ base: "5xl", md: "140px" }}
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
          w={"full"}
        >
          <Text
            color={"#01688b"}
            as={"span"}
            pr={{ base: 10, md: 40 }}
            pl={{ base: 5, md: 0 }}
          >
            GET BACK
          </Text>
          <br />
          <Text
            as="span"
            pl={{ base: 14, md: 48 }}
            pr={{ base: 5, md: 0 }}
            style={{
              WebkitTextStroke: "1px #01688B",
              color: "transparent"
            }}
          >
            ON TRACK
          </Text>
        </Heading>
      </VStack>
      <SimpleGrid mt={10} columns={{ base: 1, md: 2 }} spacing={10}>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={
              "https://getopt.com/wp-content/uploads/2022/08/Group-26-768x880.png"
            }
            objectFit={"cover"}
          />
        </Flex>
        <Stack spacing={4} pr={10} px={{ base: 5, sm: 5, md: 5 }}>
          <Text
            color={"#01688B"}
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight={"bold"}
            mb={5}
          >
            Take control of your health so you can feel, look, and be your best.
            Join the thousands of men already Optimized.
          </Text>
          {getbackontrackData.map((item, i) => (
            <Feature
              key={i}
              imageSrc={item.imageSrc}
              title={item.title}
              text={item.text}
            />
          ))}
        </Stack>
      </SimpleGrid>
    </Box>
  );
}

const teamMembers = [
  {
    name: "John Tidwell, MD",
    role: "Chief Medical Officer",
    imageSrc:
      "https://getopt.com/wp-content/uploads/2022/08/4533%EF%B8%BF%EF%BF%A3%EF%B8%B6%EF%BF%A3%EF%B8%BF3.png"
  },
  {
    name: "Graham Simpson, MD",
    role: "Medical Director",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/tf32.png"
  },
  {
    name: "Ranjith Ramasamy, MD",
    role: "Chief Medical Officer",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/54543f.png"
  },
  {
    name: "Jeremie Walker, MD, MBA",
    role: "Opt Health Physician",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/45dasdasd.png"
  }
];

export default teamMembers;

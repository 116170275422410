import {
  Box,
  Image,
  Text,
  Heading,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  ModalContent,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast
} from "@chakra-ui/react";
import { User, formDataType } from "../../types";
import MainBackground from "./assets/main-background.webp";
import { ReviewCard } from "./components/reviewCard";
import { FeaturesCard } from "./components/featuresCard";
import Schedule from "./assets/schedule.webp";
import Call from "./assets/call.webp";
import Pharmacist from "./assets/pharmacist.webp";
import Phone from "./assets/phone-pana.webp";
import Logo from "./assets/logo.webp";
import { Faqs } from "./components/faqs";
import { Form } from "./components/form";
import { useEffect, useState } from "react";
import { authorize, checkExistence } from "../../config/apis/snap";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
interface Props {
  user: User;
  children?: React.ReactNode;
}
const domain = "dev-ymksvea6x1glj2fu.us.auth0.com";
export const Ss: React.FC<Props> = ({ user }) => {
  return (
    <Auth0Provider
      domain={domain}
      clientId="WviJ7duhTeLCtUYkaV2uedramn7w6UYT"
      authorizationParams={{
        redirect_uri: "http://localhost:3004/snap_callback",
        // prompt: "consent",
        // audience: `https://${domain}/api/v2/`,
        scope: "read:current_user"
      }}
    >
      <SsChild user={user} />
    </Auth0Provider>
  );
};

export const SsChild: React.FC<Props> = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState<formDataType>({} as formDataType);
  const {
    loginWithPopup,
    user: auth0User,
    isAuthenticated,
    isLoading: isAuth0Loading,
    getAccessTokenWithPopup,
    logout
  } = useAuth0();
  const toast = useToast();
  const [status, setStatus] = useState<"signup" | "redirect">("signup");
  // check if user exists in ss
  const handleSubmit = async (params: formDataType) => {
    setIsLoading(true);
    setError("");
    setFormData(params);
    try {
      const existence = await checkExistence({ email: params.email });
      console.log(existence);
      if (existence.status === 200) {
        if (existence.message === "user does not exist") {
          loginWithPopup();
        } else if (existence.message === "redirect to reward selection") {
          setStatus("redirect");
          loginWithPopup();
        }
        // setShowPasswordModal(true);
      }
      // else {
      //   loginWithPopup();
      // }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError("Something went wrong");
    }
  };
  // call create lead api
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowPasswordModal(false);
    setShowSuccessModal(true);
  };
  useEffect(() => {
    // logout();
    if (isAuthenticated) {
      console.log(auth0User);
      const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenWithPopup({
            authorizationParams: {
              audience: `https://${domain}/api/v2/`,
              scope: "read:current_user"
            }
          });
          console.log(accessToken);
          const userDetailsByIdUrl = `https://${domain}/api/v2/users/${auth0User?.sub}`;
          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          const user_metadata = await metadataResponse.json();
          console.log(user_metadata);
          if (formData.email === user_metadata.email) {
            const response = await authorize({
              user_id: user_metadata.identities[0].user_id
            });
            console.log(response);
          } else {
            toast({
              title: "Invalid email address",
              description: "You have used an invalid email address"
            });
            setTimeout(() => {
              window.localStorage.setItem(
                "redirectTo",
                window.location.pathname
              );
              logout({
                logoutParams: {
                  returnTo: window.location.origin + "/snap_logout"
                }
              });
            }, 1000);
          }
          // setUserMetadata(user_metadata);
        } catch (e: any) {
          console.log(e.message);
        }
      };
      getUserMetadata();
    }
    window.localStorage.removeItem("redirectTo");
  }, [isAuthenticated]);
  return (
    <Box height={"100%"}>
      <Box position={"relative"} height={"100%"}>
        <Image
          src={MainBackground}
          objectFit={"cover"}
          position={"absolute"}
          h="100%"
          w="100%"
          // visibility={"hidden"}
          //   minH={"100%"}
          //   minW={"100%"}
          boxSizing="border-box"
        />
        <Box
          position={"absolute"}
          display={"flex"}
          flexDir={["column", "row"]}
          alignItems={"center"}
          zIndex={20}
          p={5}
          px={20}
          pt={10}
          justifyContent={"space-between"}
          w="100%"
        >
          <Image src={Logo} h="50px" />
          <Button
            colorScheme="blue"
            bgColor={"rgb(38, 91, 209)"}
            size={"lg"}
            rounded={"full"}
            onClick={onOpen}
          >
            Book now
          </Button>
        </Box>
        <Box
          zIndex={10}
          // bgColor={"red"}
          display={"flex"}
          // verticalAlign={"middle"}
          position={"absolute"}
          alignItems={"flex-end"}
          h="100%"
          w="100%"
          justifyContent={"center"}
        >
          <Box
            h="40%"
            w="80%"
            display={"flex"}
            alignItems={"center"}
            // bgColor={"red"}
            flexDir={["column", "row"]}
            //   justifyContent={"space-around"}
            px={10}
          >
            <Box>
              <Heading color="white" w="70%">
                With {user.full_name?.split?.(" ")?.[0] || ""}'s referral,{" "}
                <br />
                Get a $100 Amazon gift card when you visit a new dentist
              </Heading>
              <Text color="white" fontSize={"10px"} mt={2}>
                Appointment must be completed by December 15th, 2023.
              </Text>
            </Box>
            <Box
              w={"fit-content"}
              zIndex={20}
              // bgColor={"white"}
              p={5}
              borderRadius={10}
              // ml={20}
            >
              {/* <Text fontWeight={"800"} fontSize={"24px"}>
                Book an appointment
              </Text> */}
              {/* <Text fontSize={"12px"} w="100%" mt={2}>
                Complete a full preventive exam to claim your Apple AirPods!
              </Text> */}
              <Button
                bgColor={"rgb(38, 91, 209)"}
                color="white"
                borderRadius={"full"}
                colorScheme="blue"
                w="full"
                mt={20}
                onClick={onOpen}
              >
                Book your appointment
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        bgColor={"rgb(239, 244, 255)"}
      >
        <Box py={10} w="80%" px={10}>
          <Heading>What our patients are saying about us?</Heading>
          <Box display={"flex"} flexDir={["column", "row"]} mt={10} gap={10}>
            <ReviewCard
              review="Thank you snapsmile for helping me cover $150 from my dental check up! Being uninsured, this helped me a lot :)"
              name="Anonymous"
              location="New Jersey"
              invertedColors={true}
            />
            <ReviewCard
              review="Thank you snapsmile for helping me cover $150 from my dental check up! Being uninsured, this helped me a lot :)"
              name="Anonymous"
              location="New Jersey"
            />
            <ReviewCard
              review="The dentists at the office was very transparent and helped me understand about my oral health. I made a decision at my own pace and went through a painless procedure the next week. Way to go snapsmile!!"
              name="Anonymous"
              location="New Jersey"
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box w="80%" mt={20}>
          <Heading>
            A friendlier dental experience starring you, as the main character
          </Heading>
          <Button
            size={"lg"}
            rounded={"full"}
            bgColor={"black"}
            color={"white"}
            mt={5}
          >
            Book an Appointment
          </Button>
          <Grid
            // display={"flex"}
            templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
            // gridColumn={2}
            gridGap={5}
          >
            <GridItem>
              <FeaturesCard
                image={Schedule}
                heading="Book same day appointments"
                subHeading="Urgent and virtual care visits on your schedule."
              />
            </GridItem>
            <GridItem>
              <FeaturesCard
                image={Call}
                heading="All your dental health in one place"
                subHeading="Access your medical charts, complete records, and test results immediately."
              />
            </GridItem>
            <GridItem>
              <FeaturesCard
                image={Pharmacist}
                heading="Chat with your dental health team"
                subHeading="Get treated for a range of symptoms & conditions right from your smartphone."
              />
            </GridItem>
            <GridItem>
              <FeaturesCard
                image={Phone}
                heading="Oral products at your doorstep"
                subHeading="Get your everyday oral care products, medications, and prescriptions delivered to your pharmacy or to your door."
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box w="100%" h={"100%"} mt={10} position={"relative"}>
        <video
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute"
          }}
          autoPlay
          muted
          loop
        >
          <source src="https://snapsmile-public.s3.ca-central-1.amazonaws.com/snapsmile-dragons-den.mp4" />
        </video>
        <Box
          h={"95%"}
          width={"100%"}
          // bgColor={"red"}
          position={"relative"}
          zIndex={10}
          display={"flex"}
          justifyContent={"center"}
          // px={}
        >
          <Box
            display={"flex"}
            w="80%"
            // px={10}
            h="100%"
            // alignItems={"flex-end"}
            flexDir={"column"}
            justifyContent={"flex-end"}
            // bgColor={"red"}
          >
            <Heading color={"white"} opacity={0.9} w="70%">
              Snapsmile was made to help anyone get access to oral healthcare.
              The first step to preventing major health issues is by monitoring,
              and taking action before it happens.
            </Heading>
            <Button
              variant={"outline"}
              w={"fit-content"}
              color={"white"}
              rounded={"full"}
              mt={10}
            >
              Watch snapsmile on Dragons Den
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} w="100%" justifyContent={"center"} mt={10} pb={10}>
        <Box display={"flex"} w="80%" flexDir={["column", "row"]}>
          <Box w={["100%", "40%"]}>
            <Heading>Got Questions?</Heading>
          </Box>
          <Box w={["100%", "60%"]}>
            <Faqs />
          </Box>
        </Box>
      </Box>
      <Box textAlign={"center"} py={5}>
        <Text color={"rgb(102, 102, 102)"} fontSize={"14px"}>
          © Copyright 2021 SnapSmile. All rights reserved.
        </Text>
        <Text color={"rgb(102, 102, 102)"} fontSize={"10px"}>
          Snapsmile is a third party app and dental clinics are not responsible
          for any of the offers provided by snapsmile
        </Text>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setShowPasswordModal(false);
          setShowSuccessModal(false);
          onClose();
        }}
        scrollBehavior="inside"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={20}>
          {showPasswordModal ? (
            <Box p={10}>
              <Heading textAlign={"center"} mb={5}>
                Create your password
              </Heading>
              <form onSubmit={submit}>
                <VStack spacing={10} align="stretch">
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={formData.email}
                      // defaultValue={formData.email}
                      isDisabled
                      isReadOnly
                      // onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      name="password"
                      // value={formData.firstName}
                      // onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input
                      type="password"
                      name="cpassword"
                      // value={formData.firstName}
                      // onChange={handleInputChange}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    rounded={"full"}
                    colorScheme="blue"
                    // onClick={submit}
                    // isLoading={isLoading}
                  >
                    Submit
                  </Button>
                </VStack>
              </form>
            </Box>
          ) : showSuccessModal ? (
            <Box p={10} w="fit-content">
              <VStack spacing={4} align={"stretch"}>
                <Heading textAlign={"center"}>Success!</Heading>
                <Text textAlign={"center"} fontWeight={"500"} fontSize={"20px"}>
                  Click the button below to login and book your appointment
                </Text>
                <Button colorScheme="blue" rounded={"full"} size={"lg"} mt={5}>
                  Login
                </Button>
              </VStack>
            </Box>
          ) : (
            <Form
              onSubmit={handleSubmit}
              isLoading={isLoading}
              closeModal={() => {
                onClose();
                setShowPasswordModal(false);
              }}
              referredBy={2}
              error={error}
            />
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

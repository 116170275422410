import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Select,
  VStack,
  Heading,
  Link,
  InputGroup,
  InputLeftAddon,
  Text
} from "@chakra-ui/react";
import { formDataType } from "../../../../types";
import { useState } from "react";

export const Form = ({
  onSubmit,
  referredBy,
  error,
  isLoading
}: {
  closeModal: () => void;
  onSubmit: (params: formDataType) => void;
  isLoading: boolean;
  error: string;
  referredBy: number;
}) => {
  const [formData, setFormData] = useState<formDataType>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    fullName: "",
    referredBy
  });
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log(formData);
    onSubmit(formData);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };
  return (
    <Box p={10}>
      <Heading textAlign={"center"} mb={5}>
        Book Your Appointment
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Phone</FormLabel>
            <InputGroup>
              <InputLeftAddon children="+1" />
              <Input
                type="number"
                name="phone"
                inputMode="tel"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired>
            {/* <FormLabel>Terms</FormLabel> */}
            <VStack spacing={2} align="start">
              <Checkbox
                name="checkbox1"
                // isChecked={formData.checkbox1}
                // onChange={handleInputChange}
              >
                I agree to the{" "}
                <Link
                  isExternal
                  color="blue"
                  href="http://localhost:3001/terms"
                >
                  Terms and Conditions
                </Link>
              </Checkbox>
            </VStack>
          </FormControl>
          <Text color={"red"}>{error}</Text>
          <Button
            type="submit"
            rounded={"full"}
            colorScheme="blue"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

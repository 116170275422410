import { Box, Container, Image, Text } from "@chakra-ui/react";
import "../index.css";
import "./Section02.css";
const Section02 = () => {
  return (
    <>
      <Container
        maxW={{ base: "95%", sm: "95%", lg: "1440px" }}
        my={{ base: "12", sm: "16", md: "28" }}
        overflow="hidden"
        className="font-ginger"
      >
        <Box className="section-02" alignItems="center">
          <Box
            alignItems="center"
            gap="20"
            display={"flex"}
            justifyContent={"center"}
          >
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-1.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                className="font-ginger"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Science backed, Vet formulated
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-2.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Human-quality ingredients
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-3.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Made daily in our Sydney Kitchen
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-4.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Gently cooked, snap frozen
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-5.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                No commitment subscription
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-6.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Carbon negative
              </Text>
            </Box>
          </Box>

          <Box
            alignItems="center"
            gap="20"
            display={"flex"}
            justifyContent={"center"}
          >
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-1.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                className="font-ginger"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Science backed, Vet formulated
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-2.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Human-quality ingredients
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-3.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Made daily in our Sydney Kitchen
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-4.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Gently cooked, snap frozen
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-5.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                No commitment subscription
              </Text>
            </Box>
            <Box alignItems="center" gap="3" display={"flex"} flexDir={"row"}>
              <Image src="/img/details-6.png" className="icons" />
              <Text
                as="span"
                color="#005648"
                fontSize={["16px", "20px"]}
                fontWeight="400"
                whiteSpace={"nowrap"}
              >
                Carbon negative
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Section02;

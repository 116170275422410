import "../index.css";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import Header from "./common/Header";
export const LykaTerms = () => {
  return (
    <Box bgColor={"#dbe6dc"} w="100%" h="auto" pb={10}>
      <Header />
      <Box
        display={"flex"}
        fontWeight={"bold"}
        color={"#005648"}
        justifyContent={"center"}
        pt={20}
      >
        <Heading className="font-balgin">Lyka Gift Promotion Terms</Heading>
      </Box>

      <Box
        className="font-ginger"
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
        mt={20}
        color={"#005b47"}
      >
        <Box w={["80%", "60%"]} display={"flex"} flexDir={"column"} gap={8}>
          <Text fontSize={"1rem"}>
            From 5th December 2023 to 6th February 2024, customers who sign-up
            via a unique Gift Promotion link will be eligible to receive a free
            Lyka Starter Box. 100% free, including delivery. No purchase
            required.
          </Text>
          <Heading className="font-ginger" fontWeight={"300"} as="h2">
            Promotion Details
          </Heading>
          <UnorderedList>
            <ListItem>
              A targeted group of Lyka Customers will be eligible to gift Free
              Lyka Starter Boxes. This offer is exclusive to the group of
              customers who receive a unique Gift Promotion link via email.
            </ListItem>
            <ListItem>
              Referred customers must sign up via the Referrer’s unique link by
              the earlier of 6th February 2024, or the date listed on the
              invitation link page.
            </ListItem>
            <ListItem>
              Each new referred customer is eligible to redeem a Free Starter
              Box for 1 dog only.
            </ListItem>
            <ListItem>
              Referred customers will automatically commence their Lyka
              Subscription upon claiming their Free Starter Box. They can cancel
              or pause anytime before their second delivery is processed at no
              additional cost.
            </ListItem>
            <ListItem>
              When the Referred Customer’s 3rd order is processed Referrer will
              get 50% off their next order.
            </ListItem>
            <ListItem>
              Each Unique Referrer Link may be used up to the number of times
              communicated to the customer in the invitation email. If no limit
              is specified, beyond 5 redemptions each link may remain active
              until the expiration date at Lyka’s sole discretion.
            </ListItem>
          </UnorderedList>
          <Text>
            By participating in the Lyka Gift Promotion (“Program”), you are
            subject to Lyka's{" "}
            <Link
              href="https://lyka.com.au/privacy-and-terms"
              isExternal
              color={"#ff886b"}
              textDecor={"underline"}
            >
              Terms of Use and Privacy Policy
            </Link>
            , as well as the following terms and conditions: The Program is
            enabled through technology provided by Refer.us Co. ("Service
            Provider''). Participation in the Program is at all times subject to
            your compliance with these terms, Service Provider’s{" "}
            <Link
              href="https://www.refer.us/privacy"
              isExternal
              color={"#ff886b"}
              textDecor={"underline"}
            >
              Privacy Policy
            </Link>
            , and other terms or policies we may provide to you from time to
            time. All terms are subject to change at any time at Lyka and
            Service Provider’s sole discretion.
          </Text>
          <Text>
            Referrer. A real person who refers another real person to Lyka.
          </Text>
          <Text>
            Referred Customer. A real person in Australia who arrives at the
            Site and redeems the offer by using the unique referral link
            provided by a Referrer.
          </Text>
          <Text>
            Qualified Referral. A Qualified Referral is defined a Referred
            Customer client who is (i) in Australia, (ii) at least 18 years old,
            (iii) has not signed up for Lyka in the past, (iv) signs up through
            the unique invitation link of a Referrer (v) orders and enters promo
            code to redeem 100% off their first starter box for a single dog
            (vi) pays for and receives their third Lyka order without canceling
            their plan Referrer Discounts. 50% discount being applied to the
            next order provided to a referring Customer as a result of making a
            Qualified Referral. Referrer Discounts are determined by Lyka in its
            sole discretion, are non-transferable and are subject to change
            without notice.
          </Text>
          <Text>
            Eligibility. To be eligible to participate in the Program, Referrers
            and Referred Customers must be at least 18 years old and reside in
            Australia. Each referred entity must be new to Lyka, at least 18
            years old, and must register for Lyka using the Referrer’s unique
            invitation link. In the event that more than one Referrer claims to
            have been the original referring party, Lyka and the Service
            Provider will determine which Referrer is eligible.{" "}
          </Text>
          <Text>
            All Referrer Discounts are subject to verification. We reserve the
            right to delay issuance of a Referrer Discount for the purposes of
            investigation and confirmation of validity. We may also refuse to
            verify and process any transaction we deem, in our sole discretion,
            to be fraudulent, suspicious, in violation of these terms and
            conditions, or that we believe may impose potential liability on
            Lyka or the Service Provider, their subsidiaries, affiliates or any
            of their respective officers, directors, employees, representatives
            and agents.
          </Text>
          <Text>
            Limitations. Referrer’s unique invitation link may not be shared,
            sold, or used in any way except by the Referrer alone to obtain
            Qualified Referrals.
          </Text>
          <Text>
            Data. Referred Customer agrees that Referrer will be informed of
            their membership status for purposes of providing Referrer Gifts.
          </Text>
          <Text>
            Compliance with Applicable Law. As a Referrer, you agree to comply
            with all applicable law, including, but not limited to the CAN SPAM
            Act. Any distribution of your Program link that could constitute
            unsolicited commercial email or “spam” under any applicable law or
            regulation is expressly prohibited and will be grounds for immediate
            termination of your Account and exclusion from the Program.
          </Text>
          <Text>
            Right to Terminate Accounts. Lyka reserves the right to terminate
            the Account of any Referrer and/or Referred Customer at any time and
            without notice, including, but not limited to, if we, at our sole
            discretion consider any Referrer and/or Referred Customer conduct to
            be in violation of these Referral Program Terms and Conditions.
          </Text>
          <Text>
            Right to Cancel Program or Change Terms. Lyka reserves the right to
            cancel the Referral Program and to revise these Referral Program
            Terms from time to time. If the Referral Program is terminated, then
            any unclaimed Referrer Discounts will be forfeited at that time.
          </Text>
          <Text>
            You hereby release Lyka and the Service Provider of all claims and
            liabilities arising from or relating to the Referrer Gifts received
            by you.
          </Text>
          <Text>
            In no event will Lyka and the Service Provider be liable for any
            indirect, incidental, special, punitive or consequential damages
            arising from or relating to the Bonuses and/or the Program. All
            taxes owed with respect to the Program shall be the sole
            responsibility of the recipient.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

import { formDataType } from "../../types";
import { baseURl } from "./";
const optBaseUrl = "https://opt-health-backend.herokuapp.com";
export const create = async (
  params: formDataType & { gender: string; state: string }
) => {
  const fetchResponse = await fetch(`${baseURl}/oh/create`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const updateProfile = async ({
  customerId,
  token
}: {
  customerId: string;
  token: string;
}) => {
  const fetchResponse = await fetch(`${optBaseUrl}/api/chargify/profile`, {
    method: "PUT",
    body: JSON.stringify({ customerId }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return fetchResponse;
};

export const subscription = async ({
  subscriptionId,
  signupPaymentId,
  token
}: {
  subscriptionId: string;
  signupPaymentId: string;
  token: string;
}) => {
  const fetchResponse = await fetch(`${optBaseUrl}/api/chargify/subscription`, {
    method: "POST",
    body: JSON.stringify({
      id: subscriptionId,
      signupPaymentId,
      type: "exam",
      plan: "optimization"
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  const fetchResponse = await fetch(`${optBaseUrl}/login`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      webview: false
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const sync = async (params: {
  leadName: string;
  leadEmail: string;
  referrerName: string;
  referrerEmail: string;
}) => {
  const fetchResponse = await fetch(`${baseURl}/oh/sync`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const paymentCompleted = async (params: {
  email: string;
  subscriptionId: string;
  customerId: string;
}) => {
  const fetchResponse = await fetch(`${baseURl}/oh/payment`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const getUserProfile = async () => {
  const token = window.localStorage.getItem("token");
  const fetchResponse = await fetch(`${optBaseUrl}/api/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  })
    .then((res) => {
      if (res.status == 401) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("ohEmail");
        return {
          message: "Unauthorized",
          status: 401
        };
      }
      return res.json();
    })
    .catch((error) => {
      // if(error == )
    });
  return fetchResponse;
};

import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text
} from "@chakra-ui/react";
import { useState } from "react";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      {/* <Box w="full" bg="#005648" textAlign="center" py="2">
        <Text as="p" color="white" fontWeight="semibold">
          Get started with 20% off your first box,{" "}
          <Text as="span" color="#11b193">
            click to redeem
          </Text>
        </Text>
      </Box> */}
      <Container
        maxW={{ base: "full", xl: "1440px" }}
        py={{ base: "3", lg: "0" }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Image src="img/lyka_logo_green.svg" py={[1, 5]} />
          {/* <Text
            as="h2"
            color="#005648"
            fontWeight="bold"
            fontSize={{ base: "28px", lg: "50px" }}
            className="font-balgin"
          >
            Lyka
          </Text> */}
          {/* <Flex
            alignItems="center"
            gap="7"
            color="#005648"
            fontWeight="medium"
            display={{ base: "none", lg: "flex" }}
          >
            <Link href="">Our food</Link>
            <Link href="">How it works</Link>
            <Link href="">About us</Link>
            <Link href="">Reviews</Link>
            <Link href="">Blog</Link>
          </Flex>
          <Flex alignItems="center" gap="4">
            <Link
              href=""
              bg="#dbe6dc"
              rounded="full"
              px="7"
              py="3"
              display={{ base: "none", lg: "block" }}
            >
              <Text color="#005648" fontWeight="medium">
                Login
              </Text>
            </Link>
            <Link href="" bg="#ff886b" rounded="full" px="7" py="3">
              <Text color="white" fontWeight="medium">
                Get started
              </Text>
            </Link>
            <Button
              display={{ base: "block", lg: "none" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              {showMenu ? (
                <Image src="/img/menu-button.png" />
              ) : (
                <Image src="/img/menu-button.png" />
              )}
            </Button>
          </Flex> */}
        </Flex>
      </Container>

      {/* {showMenu && (
        <Box
          pos="fixed"
          top="28"
          left="16"
          right="16"
          rounded="lg"
          py="4"
          px="3"
          bg="white"
          shadow="lg"
          zIndex="9999"
          textAlign="center"
          display={{ base: "block", lg: "none" }}
        >
          <Link href="" display="block">
            Our food
          </Link>
          <Link href="" display="block">
            How it works
          </Link>
          <Link href="" display="block">
            About us
          </Link>
          <Link href="" display="block">
            Reviews
          </Link>
          <Link href="" display="block">
            Blog
          </Link>
        </Box>
      )} */}
    </>
  );
};

export default Header;

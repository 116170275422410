import React from "react";
import { Box, Container, useBreakpoint } from "@chakra-ui/react";
import "../index.css";
const TrustPilot = () => {
  const ref = React.useRef(null);
  // const base = useBreakpoint();

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    //@ts-ignore
    if (window.Trustpilot) {
      //@ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  // console.log(base);

  return (
    <>
      <Container
        display={"flex"}
        // justifyContent={"center"}
        marginTop={"-25"}
        width={[300, 480]}
        // maxW={"20%"}
      >
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget" // Renamed this to className.
          // [ long list of data attributes...]
          data-locale="en-AU"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-businessunit-id="5e1521e66a6fea0001d2a410"
          data-style-height="20px"
          data-style-width="100%"
          data-theme="light"
          data-tags=""
          data-schema-type=""
          data-stars=""
          style={{
            // maxWidth: "80%"
            backgroundColor: "white",
            borderColor: "#DBE6DC",
            borderWidth: 1,
            height: 50,
            display: "flex",
            alignItems: "center",
            borderRadius: 10,
            width: "100%"
            // paddingTop: 10,
            // paddingBottom: 10,
          }}
          // className="trustpilot-widget trustpilot-widget"
        >
          <a
            href="https://uk.trustpilot.com/review/lyka.com.au"
            target="_blank"
            rel="noopener"
          >
            {" "}
            Trustpilot
          </a>
        </div>
      </Container>
      {/* <Container
        maxW={{ base: "full", sm: "530px" }}
        mt="-5"
        pos="relative"
        zIndex="99999"
      >
        <Box shadow="lg" rounded="xl" py="3" px="8" bg="white">
          <Flex
            alignItems="center"
            gap="3"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Text as="span" fontWeight="600">
              {" "}
              Excellent
            </Text>
            <Image src="/img/trustpilot.png" w="full" maxW="100px" />
            <Text as="span" fontSize="14px">
              <Text as="strong">2,239 </Text>
              reviews on
            </Text>
            <Image src="/img/trustpilot-2.png" w="full" maxW="75px" />
          </Flex>
        </Box>
      </Container> */}
    </>
  );
};

export default TrustPilot;

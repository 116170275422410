import { Container, Text, Flex, Image, Box } from "@chakra-ui/react";
import "./GetStartedSection.css";

const GetStartedSection = () => {
  return (
    <Container
      maxW="5xl"
      w="full"
      mx="auto"
      py="20"
      mt={{ base: "0", lg: "20" }}
    >
      <Text
        as="h2"
        textAlign="center"
        fontSize={{ base: "25px", md: "40px" }}
        fontWeight="600"
        className="font-fragment"
      >
        How to get your ₱5500 Bonus
      </Text>
      <Flex
        flexDir="column"
        gap="7"
        maxW="600px"
        w="full"
        mx="auto"
        mt="12"
        fontFamily={"PP Neue Montreal"}
      >
        <Flex
          alignItems="center"
          gap="5"
          flexDir={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Box flex="1">
            <Image
              src="/img/section-07-img-1.png"
              w={{ base: "60px", md: "auto" }}
            />
          </Box>
          <Box flex="5">
            <Text
              as="h3"
              fontSize="22px"
              fontWeight="600"
              mb="3"
              className="font-fragment"
            >
              1. Apply
            </Text>
            <Text fontWeight="400" fontSize="15">
              To apply, you must be 18 years or older and have an SSN. Depending
              on your credit history, you may be required to place a security
              deposit by connecting your bank.
            </Text>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          gap="5"
          flexDir={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Box flex="1">
            <Image
              src="/img/section-07-img-2.png"
              w={{ base: "60px", md: "auto" }}
            />
          </Box>
          <Box flex="5">
            <Text
              as="h3"
              fontSize="22px"
              fontWeight="600"
              mb="3"
              className="font-fragment"
            >
              2. Invite
            </Text>
            <Text fontWeight="400" fontSize="15">
              Add up to 3 authorized cardholders in the Philippines. They will
              need a smartphone and a valid ID (see list of accepted IDs). Your
              account won't be activated until at least one beneficiary
              completes their set-up!
            </Text>
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          gap="5"
          flexDir={{ base: "column", md: "row" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Box flex="1">
            <Image
              src="/img/section-07-img-3.png"
              w={{ base: "60px", md: "auto" }}
            />
          </Box>
          <Box flex="5">
            <Text
              as="h3"
              fontSize="22px"
              fontWeight="600"
              mb="3"
              className="font-fragment"
            >
              3. Spend
            </Text>
            <Text fontWeight="400" fontSize="15">
              Cards arrive in 10 days or less after you approve your
              beneficiary. 🇺🇸 You get a card too. They spend in the Philippines,
              you pay off the balance monthly. Get your ₱5500 Bonus to send once
              they use their card! 🎉
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Text
        as="h2"
        textAlign="center"
        fontSize={{ base: "25px", md: "40px" }}
        fontWeight="600"
        mt="16"
        color="#f0477a"
        className="font-fragment"
      >
        Our Partners
      </Text>
      <Flex
        gap="10"
        mx="auto"
        maxW="500"
        w="full"
        mt="12"
        flexDir={{ base: "column", md: "row" }}
        alignItems="center"
      >
        <Image src="/img/section-07-img-4.svg" flex="1" w="full" maxW="220px" />
        <Image src="/img/section-07-img-5.svg" flex="1" w="full" maxW="220px" />
      </Flex>
    </Container>
  );
};

export default GetStartedSection;

import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Image,
  Input,
  Text,
  Link,
  FormControl,
  Modal,
  useDisclosure,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";
import "../index.css";
import { User } from "../../../types";
import { useState } from "react";
import { addTempLead } from "../../../config/apis";
interface Props {
  user: User;
  children?: React.ReactNode;
}
const user_cut_off_id = 4984;
const HeroSectionNew: React.FC<Props> = ({ user }) => {
  const [email, setEmail] = useState("");
  const [checked, setIsChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isUserCutOff = user.id < user_cut_off_id;
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (user.id < user_cut_off_id || Number(user.json_data?.lyka_synced) >= 5) {
    onOpen();
    return;
    // }
    // addTempLead({ user_identifier: email, lead_id: user.id });
    // setTimeout(() => {
    //   window.location.replace(
    //     `https://lyka.com.au/get-started?cp=${user.json_data.promo_code}`
    //   );
    // }, 1000);
  };
  const handleNoPromoSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    addTempLead({ user_identifier: email, lead_id: user.id });
    setTimeout(() => {
      window.location.replace(`https://lyka.com.au/get-started`);
    }, 1000);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        scrollBehavior="inside"
        size={"xl"}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent borderRadius={20} mx={2}>
          <Box
            boxSize={"xs"}
            w="100%"
            className="font-ginger"
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
          >
            <Box
              h="40px"
              w="100%"
              bgColor={"#DDE6DD"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderTopLeftRadius={20}
              borderTopRightRadius={20}
            >
              <Text color="#005b47">Free Starter Box Expired</Text>
            </Box>
            <Box
              w="70%"
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              h="100%"
            >
              <Text
                mt={0}
                fontSize={"16px"}
                color="#005b47"
                textAlign={"center"}
              >
                Looks like {user?.full_name?.split?.(" ")?.[0]}’s one free
                Starter Box{" "}
                {isUserCutOff
                  ? "expired on 31st December 2023."
                  : "has expired."}
                <br />
                <br />
                But you can still get 20% off your first box by continuing
                below!
              </Text>
              <form onSubmit={handleNoPromoSubmit}>
                <Flex
                  // borderWidth="1px"
                  // borderColor="#dbd2c2"
                  // rounded="full"
                  // bg="#fcfbf9"
                  mt="10"
                  className="font-ginger"
                  justifyContent={"center"}
                >
                  <Button
                    bg="#ff886b"
                    color="white"
                    py="3"
                    px="10"
                    rounded="full"
                    className={"font-ginger"}
                    type="submit"
                    _hover={{ backgroundColor: "#F1AA94" }}
                  >
                    Continue
                  </Button>
                </Flex>
              </form>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
      <Box bg="#fffaea" py={{ base: "14", lg: "16" }}>
        <Container maxW={{ base: "full", lg: "1200px" }}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            gap={{ base: "10", lg: "28" }}
            flexDir={{ base: "column", lg: "row" }}
          >
            <Box flex="2">
              <Text
                as="h1"
                color="#005b47"
                fontSize={{ base: "2.25rem", lg: "4rem" }}
                fontWeight="bold"
                maxW={{ base: "full", lg: "500px" }}
                lineHeight="1.1em"
                textAlign={{ base: "center", lg: "left" }}
                className="font-balgin"
              >
                <Text as="span" color="#11b193">
                  {user?.full_name?.split?.(" ")?.[0]}
                </Text>{" "}
                sent you{" "}
                <Text as="span" color="#11b193">
                  a free Lyka Starter Box{" "}
                </Text>
                of meals for your pup
              </Text>
              <Text
                className={"font-ginger"}
                color="#005648"
                mt={2}
                textAlign={{ base: "center", lg: "left" }}
              >
                100% free, including delivery. No purchase required.
                {!isUserCutOff && <Text>Expires 6th February 2024.</Text>}
              </Text>
              <Box
                maxW={{ base: "full", lg: "450px" }}
                display={{ base: "none", lg: "block" }}
                mt="8"
              >
                <Text
                  as="p"
                  color="#005648"
                  fontSize="14px"
                  fontWeight="semibold"
                  className={"font-ginger"}
                >
                  Enter your email to receive your gift
                </Text>
                <form onSubmit={handleSubmit}>
                  <Flex
                    borderWidth="1px"
                    borderColor="#dbd2c2"
                    rounded="full"
                    bg="#fcfbf9"
                    my="3"
                    className="font-ginger"
                  >
                    <FormControl isRequired>
                      <Input
                        type="email"
                        borderWidth="0px"
                        isRequired
                        _focusVisible={{ outline: "none" }}
                        rounded="full"
                        placeholder="Email address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </FormControl>
                    <Button
                      bg="#ff886b"
                      color="white"
                      py="3"
                      px="10"
                      rounded="full"
                      className={"font-ginger"}
                      type="submit"
                      _hover={{ backgroundColor: "#F1AA94" }}
                    >
                      Accept gift
                    </Button>
                  </Flex>

                  <Flex alignItems="center" gap="4">
                    <FormControl isRequired>
                      <Checkbox
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "rgb(16,177,147)",
                            borderWidth: 1,
                            borderColor: "rgb(16,177,147)"
                          },
                          "& .chakra-checkbox__hover": {
                            background: "rgb(16,177,147)",
                            borderWidth: 0
                          },
                          _hover: {
                            "& .chakra-checkbox__control": {
                              background: "rgb(16,177,147)",
                              borderWidth: 1,
                              borderColor: "rgb(16,177,147)"
                            }
                          }
                        }}
                        // _hover={{
                        //   "& .chakra-checkbox__control": {
                        //     background: "rgb(16,177,147)",
                        //     borderWidth: 1,
                        //     borderColor: "rgb(16,177,147)"
                        //   }
                        // }}
                      >
                        <Text
                          as="p"
                          color="#005648"
                          fontSize="13px"
                          fontWeight="semibold"
                        >
                          I accept the{" "}
                          <Link
                            isExternal
                            href="https://www.lyka.refer.us/terms"
                            textDecor={"underline"}
                          >
                            Terms & Conditions
                          </Link>
                        </Text>
                      </Checkbox>
                    </FormControl>
                  </Flex>
                </form>
              </Box>
            </Box>
            <Box flex="2">
              <Image
                src="https://lykaassets.s3.amazonaws.com/RafFestiveOffer_Mock_Updated.png"
                w="full"
              />

              <Box
                display={{ base: "block", lg: "none" }}
                mt="8"
                textAlign="center"
              >
                <Text
                  as="p"
                  color="#005648"
                  fontWeight="semibold"
                  fontSize="14px"
                  className="font-ginger"
                >
                  Enter your email to receive your gift
                </Text>
                <form onSubmit={handleSubmit}>
                  <Flex
                    borderWidth="1px"
                    borderColor="#dbd2c2"
                    rounded="full"
                    bg="#fcfbf9"
                    my="3"
                    className="font-ginger"
                  >
                    <FormControl isRequired>
                      <Input
                        type="email"
                        borderWidth="0px"
                        _focusVisible={{ outline: "none" }}
                        rounded="full"
                        placeholder="Email address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Flex>

                  <Flex alignItems="center" gap="4" justifyContent="center">
                    <FormControl isRequired>
                      <Checkbox
                        _checked={{
                          "& .chakra-checkbox__control": {
                            background: "rgb(16,177,147)",
                            borderWidth: 1,
                            borderColor: "rgb(16,177,147)"
                          },
                          "& .chakra-checkbox__hover": {
                            background: "rgb(16,177,147)",
                            borderWidth: 0
                          },
                          _hover: {
                            "& .chakra-checkbox__control": {
                              background: "rgb(16,177,147)",
                              borderWidth: 1,
                              borderColor: "rgb(16,177,147)"
                            }
                          }
                        }}
                      >
                        <Text
                          as="p"
                          color="#005648"
                          fontSize="13px"
                          fontWeight="semibold"
                          className="font-ginger"
                        >
                          I accept the{" "}
                          <Link
                            isExternal
                            href="https://www.lyka.refer.us/terms"
                            textDecor={"underline"}
                          >
                            Terms & Conditions
                          </Link>
                        </Text>
                      </Checkbox>
                    </FormControl>
                  </Flex>

                  <Button
                    mt="4"
                    bg="#ff886b"
                    color="white"
                    py="3"
                    px="10"
                    rounded="full"
                    type="submit"
                    className="font-ginger"
                    _hover={{ backgroundColor: "#F1AA94" }}
                  >
                    Accept gift
                  </Button>
                </form>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default HeroSectionNew;

const testinomialData = [
  {
    name: "Mar R.",
    description:
      "Such a great experience with Opt Health. The telemedicine platform and dashboard makes it super easy to use and engage with. Always on the quest to living a better, healthier life my Opt doctor found areas I could improve and came up with a plan to help get me there. Would absolutely recommend.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/mat.png"
  },
  {
    name: "Thomas C.",
    description:
      "A great experience. Had my labs and exam done in the comfort of my home. The nurse was professional and explained the purpose for each part of the exam. I had my results two days later and spoke with a doctor the day after that. The video platform worked well which I was worried about before we connected. The doctor and I talked through each part of my health, from my diet and exercise routines to each metric of my blood test. It was unlike any conversation I’ve had with a doctor before and end extremely insightful. I learned a ton about my health and now have benchmarks to keep track of. 5/5 experience that I’ll be continuing.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/thomas.png"
  },
  {
    name: "Ryan R.",
    description:
      "Before Opt Health I was tired and could barely make it through 12 noon before needing a nap.  I lacked energy and motivation.  Since being with Opt Health for the last few months, my energy has increased, I no longer nap, I’m more present and available for my wife and children, and my overall attitude and demeanor has changed.  Opt helped with my thyroid, vitamin deficiencies, and testosterone.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/ryan.png"
  },
  {
    name: "Santos T.",
    description:
      "I am finally getting back to feeling as good as I did when I was in my prime as a Navy SEAL in only a few months. I have lost thirty pounds; I can keep up with my kids again and Dr. Simpson identified a health condition that was quickly ending my life. Everything has been positive for me; I get along better with my wife, I sleep much better, and I am able to concentrate and work much more efficiently. I am enjoying my life a lot more now and I recommend the program for anyone that is wanting to get back to feeling like themselves again. I wish I had found this program years ago.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/santos.png"
  },
  {
    name: "Steve G.",
    description:
      "I decided to try Opt Health’s telemedicine platform a couple of months ago and so far my experience has been nothing short of fantastic. Both the initial intake questionnaire and physical exam (including bloodwork) were thorough and comprehensive yet very convenient and easy. After my lab results were ready, I had my video call consultation with Dr. Simpson. During our call we reviewed my intake questionnaire, physical exam and lab results and, based on all of this, Dr. Simpson created a tailor made plan for me. If you’re interested in taking care of yourself, maximizing your physical potential and feeling great, I’d definitely recommend giving Opt Health a try.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/steve.png"
  },
  {
    name: "Don S.",
    description:
      "I have been involved in competitive athletics over 50 years competing at an elite level in Rowing and Cycling.  I have always had a structured training plan and great coaches and mentors.  I now enjoy sports more recreationally but still try to train in a disciplined manner.  With age my performance seems to be slipping.  I have been using Opt Health for a few months now to be more systematic in my nutrition and health.  Since working with Dr. Simpson I feel my performance steadily improving.  Every once in a while I even show the young guys I still have it!  I also have to thank the staff at Opt Health for the speedy and competent knowledge when answering questions regarding my specific plan.  I feel Opt Health is a great resource for anyone wanting to improve their overall health as well as athletic performance.",
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/don.png"
  }
];

export default testinomialData;

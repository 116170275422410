import Header from "./components/common/Header";
import Section02 from "./components/Section02";
import Section03 from "./components/Section03";
import TrustPilot from "./components/TrustPilot";

import Section06 from "./components/Section06";

import { User } from "../../types";
import HeroSectionNew from "./components/HeroSectionNew";
import Section13 from "./components/Section13";
import "./assets/fonts/BalginBold_SmCondensed_Lyka.otf";
import "./assets/fonts/F37Ginger_Regular_Lyka.otf";
import "./index.css";
import { Skeleton } from "@chakra-ui/react";
interface Props {
  user: User;
  children?: React.ReactNode;
}
export const Lyka: React.FC<Props> = ({ user }) => {
  return (
    <>
      <Header />
      <Skeleton isLoaded={!!user.id}>
        <HeroSectionNew user={user} />
        <TrustPilot />
        <Section02 />
        <Section03 />
        <Section06 />
        {/* <Section13 /> */}
      </Skeleton>
    </>
  );
};

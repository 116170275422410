import { baseURl } from "./";

export const checkExistence = async ({ email }: { email: string }) => {
  const fetchResponse = await fetch(`${baseURl}/snapsmile/check-existence`, {
    method: "POST",
    body: JSON.stringify({
      email
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const authorize = async ({ user_id }: { user_id: string }) => {
  const fetchResponse = await fetch(`${baseURl}/snapsmile/authorize`, {
    method: "POST",
    body: JSON.stringify({
      user_id
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

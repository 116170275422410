import React, { useState } from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  Flex,
  Image,
  SimpleGrid,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody
} from "@chakra-ui/react";
import designforyoursucessData from "../../data/designforyoursucess.data";
import { BiRightArrow } from "react-icons/bi";

interface FeatureProps {
  title: string;
  firstText: string;
  secondText: string;
  imageSrc: string;
}

const Feature = ({ title, firstText, secondText, imageSrc }: FeatureProps) => {
  return (
    <Stack flexDirection={"row"} spacing={5}>
      <Image
        boxSize="60px"
        objectFit="cover"
        src={imageSrc}
        alt="Your Image Alt Text"
      />
      <Flex direction={"column"} color={"#01688b"}>
        <Text
          fontSize={{ sm: "2xl", md: "3xl" }}
          fontWeight={"semibold"}
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
        >
          {title}
        </Text>
        <Text>{firstText}</Text>
        <Text mt={3}>{secondText}</Text>
      </Flex>
    </Stack>
  );
};

export default function DesignedForYourSuccessSection() {
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box py={10} maxW={"6xl"} mx={"auto"}>
      <VStack spacing={2} textAlign="center" px={{ base: 5, sm: 5, md: 5 }}>
        <Heading
          as="h2"
          fontSize={{ base: "3xl", md: "4xl", lg: "65px" }}
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
        >
          <Text color={"#01688b"} as={"span"}>
            DESIGNED FOR YOUR
          </Text>
          <br />
          <Text
            as="span"
            style={{
              WebkitTextStroke: "1px #01688B",
              color: "transparent"
            }}
          >
            SUCCESS
          </Text>
        </Heading>
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          maxW={"3xl"}
          color={"#444444"}
          fontWeight={"semibold"}
        >
          {`Opt Health programs operate in three simple steps. Measure. Mentor. Monitor. We're your go-to partner to set you on a course to achieve your health goals.`}
        </Text>
      </VStack>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={10}
        mt={{ base: 10, md: 20 }}
      >
        <Flex justify="center" align="center">
          <Box position="relative">
            <Image
              alt={"feature image"}
              src={
                "https://getopt.com/wp-content/uploads/2023/09/Dr-video-image.webp"
              }
              objectFit={"cover"}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            <Button
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              bg={"#01688b"}
              color={"white"}
              rounded={"full"}
              px={isHovered ? 8 : 6}
              py={isHovered ? 10 : 8}
              onClick={handleButtonClick}
              _hover={{ background: "#01688b" }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <BiRightArrow
                type="solid"
                transform={isHovered ? "scale(1.4)" : "scale(1)"}
              />
            </Button>
          </Box>
        </Flex>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="2xl">
          <ModalOverlay backgroundColor={"blackAlpha.800"} />
          <ModalContent>
            <ModalBody backgroundColor={"black"}>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/JfW1I7zTNsA"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Stack spacing={4} pr={10} px={{ base: 10, sm: 10, md: 5 }}>
          {designforyoursucessData.map((item, i) => (
            <div key={i}>
              <Feature
                title={item.title}
                firstText={item.firstText}
                secondText={item.secondText}
                imageSrc={item.imageSrc}
              />
              {i !== designforyoursucessData.length - 1 && (
                <Divider
                  pt={8}
                  style={{
                    borderStyle: "solid",
                    borderColor: "black"
                  }}
                />
              )}
            </div>
          ))}
          <Text color={"#01688B"} fontSize={"md"} fontWeight={"normal"} mt={5}>
            * In-home nurse exams are currently available in the Los Angeles
            Area, San Francisco Bay Area, San Diego County, NYC and the
            surrounding areas. Other patients may visit our local lab partners
            for bloodwork.
          </Text>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}

import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import "./MarketingSection.css";

const MarketingSection = () => {
  return (
    <Container maxW="5xl" w="full" mx="auto" py="20">
      <Flex
        gap={{ base: "5", md: "10", lg: "20" }}
        alignItems="center"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box flex="1" textAlign="center">
          <Image src="/img/section-04-img-1.svg" mx="auto" />
          <Text
            as="h3"
            fontSize="25"
            fontWeight="600"
            mb="1"
            className="font-fragment"
          >
            Goodbye cash pickups
          </Text>
          <Text fontSize="14" color="#190e34">
            Say goodbye to the hassle of cash pickups and banks that are
            offline.
          </Text>
        </Box>
        <Box flex="1" textAlign="center">
          <Image src="/img/section-04-img-2.svg" mx="auto" />
          <Text
            as="h3"
            fontSize="25"
            fontWeight="600"
            mb="1"
            className="font-fragment"
          >
            Where’s my money?
          </Text>
          <Text fontSize="14" color="#190e34">
            Never wonder where your money is. It never leaves until you pay the
            bill.
          </Text>
        </Box>
        <Box flex="1" textAlign="center">
          <Image src="/img/section-04-img-3.svg" mx="auto" />
          <Text
            as="h3"
            fontSize="25"
            fontWeight="600"
            mb="1"
            className="font-fragment"
          >
            Ready for emergencies
          </Text>
          <Text fontSize="14" color="#190e34">
            The Pomelo Card™ makes money ready 24/7 so you are ready for life’s
            surprises.
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default MarketingSection;

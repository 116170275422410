import { Button, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
interface NavItem {
  normallabel: string;
  colorlabel: string;
  subLabel?: string;
  btnLabel?: string;
  imageSrc?: string;
  isAppImage?: boolean;
  btnClick?: () => void;
}

export default function Hero({
  normallabel,
  colorlabel,
  subLabel,
  btnLabel,
  imageSrc,
  isAppImage = false,
  btnClick = () => {}
}: NavItem) {
  return (
    <Stack
      pt={20}
      minH={"75vh"}
      direction={{ base: "column", md: "row" }}
      maxW={isAppImage ? "full" : "6xl"}
      mx={"auto"}
    >
      <Flex
        py={8}
        flex={1}
        align={"center"}
        justify={"center"}
        px={{ base: 10, sm: 10, md: 0 }}
      >
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
            fontSize={{ base: "3xl", md: "4xl", lg: "65px" }}
            className="font-mantblack"
            style={{
              fontFamily: "Montblancbold"
            }}
          >
            {normallabel && (
              <Text as={"span"} position={"relative"} color={"#444444"}>
                {normallabel}
              </Text>
            )}
            <br />{" "}
            <Text color={"#01688b"} as={"span"}>
              {colorlabel}
            </Text>{" "}
          </Heading>
          <Text
            fontSize={{ base: "md", lg: "lg" }}
            color={"#444444"}
            fontWeight={"semibold"}
          >
            {subLabel}
          </Text>
          {!normallabel && (
            <Text fontSize={"md"} fontWeight={"bold"} color={"red"}>
              Expired
            </Text>
          )}
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              rounded={"md"}
              onClick={btnClick}
              bg={"#01688b"}
              color={"white"}
              _hover={{
                bg: "blue.500"
              }}
              size={"lg"}
            >
              {btnLabel}
            </Button>

            {/* <Button rounded={"full"}>How It Works</Button> */}
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={"Login Image"} objectFit={"cover"} src={imageSrc} />
      </Flex>
    </Stack>
  );
}

const getbackontrackData = [
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/Group-30.png",
    title: "More Energy",
    text: "End the era of waking up tired. Optimization plans focus on bringing back youthful energy into men’s lives."
  },
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/Group-28.png",
    title: "Improved Sleep",
    text: "Sleep is one of the most important components of living an Optimized life. Hone in your sleep schedule, routine, and supplements to sleep deeper and wake up with more energy."
  },
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/43543.png",
    title: "Sharpened Focus",
    text: "Clear out the brain fog. Bring a renewed focus into your life through your personalized Optimization plan."
  },
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/Group-29.png",
    title: "Increased Libido",
    text: "Sexual health is an important component of a man’s life. Optimize your sexual health by solving issues with ED or renewing excitement and passion in the bedroom."
  }
];

export default getbackontrackData;

import { Spinner, Center, Box, Button } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  paymentCompleted,
  subscription,
  updateProfile
} from "../../../config/apis/oh";

export const CallBack = () => {
  useEffect(() => {
    completeProfile();
  }, []);
  const completeProfile = async () => {
    const token = window.localStorage.getItem("token") as string;
    const email = window.localStorage.getItem("ohEmail") as string;
    const query = new URLSearchParams(window.location.search);
    const customerId = query.get("customerId") as string;
    const signupPaymentId = query.get("signupPaymentId") as string;
    const subscriptionId = query.get("subscriptionId") as string;
    try {
      await updateProfile({ customerId, token });
      await subscription({
        subscriptionId,
        signupPaymentId,
        token
      });
      await paymentCompleted({
        email,
        subscriptionId,
        customerId
      });
      window.parent.location.href = "https://app.getopt.com/bhrt-intake?step=0";
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      h="100%"
    >
      <Spinner size={"xl"} />
    </Box>
  );
};

import {
  Box,
  Text,
  Heading,
  OrderedList,
  ListItem,
  UnorderedList,
  List,
  Link
} from "@chakra-ui/react";

import BaseLayout from "../layout";

export const OhTerms = () => {
  return (
    <BaseLayout>
      <Box
        pt={20}
        display={"flex"}
        flexDir={"column"}
        gap={5}
        bgColor={"#01688b"}
        color="white"
        px={[10, 20]}
        pb={20}
      >
        <Heading
          textAlign={"center"}
          // fontFamily={"MontBlancBold"}
        >
          VIP Invite Program Terms{" "}
        </Heading>
        <Text>
          The Opt Health community is the driving force in helping thousands of
          men reconnect with health, wellness, strength, and vitality through
          the science of preventive medicine. To make it easier for our most
          valued clients to share Opt Health with friends and family, we’re
          offering a limited number of Opt Health VIP Invites, only until March
          31, 2024.
        </Text>
        <Heading>What are Opt Health VIP Invites?</Heading>
        <Text>
          They’re private, limited, and personalized invites to Opt Health.
          These invites come with benefits for both parties that we’ve never
          offered before, so our clients can help their friends and family take
          control of their health.
        </Text>
        <Heading>How do they work?</Heading>
        <OrderedList>
          <ListItem>
            Opt Health clients get to gift their friends a comprehensive initial
            lab assessment covering 60+ biomarkers, plus a doctor consultation,
            totally free of charge if they join Opt Health! This is not a public
            offer – this is only available with the invitation of an Opt Health
            client, and only until March 31.
          </ListItem>
          <ListItem>
            For each referral a client makes that continues to their second
            month of membership, we’ll give the referrer a $100 rebate as a
            thank you for helping to grow the Opt Health community.
          </ListItem>
        </OrderedList>
        <Heading>How do I start?</Heading>
        <Text>
          Clients who have been invited to participate in the program can share
          their unique VIP invite link received over email.
        </Text>
        <Heading as="h2">Details</Heading>
        <UnorderedList>
          <ListItem>
            Referred clients must sign up before March 31, 2024 using the unique
            invitation link of an existing client to be eligible.
          </ListItem>
          <ListItem>
            Referred clients will pay $0 to sign up, complete their lab
            assessment and doctor consultation.
          </ListItem>
          <ListItem>
            Referred clients can cancel anytime before their lab assessment
            without penalty. Upon completion of the initial lab assessment, if
            no monthly membership plan is started, a $49 fee will be charged to
            the referred client.
          </ListItem>
          <ListItem>
            The $100 rebate will be sent to the referrer after the referred
            client completes their second monthly membership payment.
          </ListItem>
        </UnorderedList>
        <Text>
          By participating in the Opt Health VIP Invite Program (“Program”), you
          are subject to Opt Health's{" "}
          <Link
            href="https://getopt.com/terms-of-use/"
            textDecorationLine={"underline"}
            isExternal
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            href="https://getopt.com/privacy-policy/"
            textDecorationLine={"underline"}
            isExternal
          >
            Privacy Policy
          </Link>
          , as well as the following terms and conditions: The Program is
          enabled through technology provided by Refer.us Co. ("Service
          Provider''). Participation in the Program is at all times subject to
          your compliance with these terms, Service Provider’s{" "}
          <Link
            href="https://getopt.com/privacy-policy/"
            textDecorationLine={"underline"}
            isExternal
          >
            Privacy Policy
          </Link>
          , and other terms or policies we may provide to you from time to time.
          All terms are subject to change at any time at Opt Health and Service
          Provider’s sole discretion.
        </Text>
        <Text>Definitions:</Text>
        <Text>
          Referrer. A real person who refers another real person to Opt Health.
        </Text>
        <Text>
          Referred Customer. A real person in the US who arrives at the Site and
          redeems the offer by using the unique referral link provided by a
          Referrer.
        </Text>
        <Text>
          Qualified Referral. A Qualified Referral is defined a Referred client
          who is (i) in the US, (ii) at least 18 years old, (iii) has not signed
          up for Opt Health in the past, (iv) signs up through the unique
          invitation link of a Referrer (v) completes their initial assessment
          with Opt Health, (vi) completes their second monthly membership
          payment.
        </Text>
        <Text>
          Referral Rebate. Rebate provided to a Referred client as a result of
          making a Qualified Referral. Referral Rebates are determined by Opt
          Health in its sole discretion, are non-transferable and are subject to
          change without notice.
        </Text>
        <Text>Eligibility.</Text>
        <Text>
          To be eligible to participate in the Opt Health Referral Program,
          Referrers and Referred clients must be at least 18 years old and
          reside in the US. Each referred entity must be new to Opt Health, at
          least 18 years old, and must register for Opt Health using the
          Referrer’s unique invitation link. In the event that more than one
          Referrer claims to have been the original referring party, Opt Health
          and the Service Provider will determine which Referrer is eligible.
        </Text>
        <Text>
          All Referral Rebates are subject to verification. We reserve the right
          to delay issuance of a Referral Rebate for the purposes of
          investigation and confirmation of validity. We may also refuse to
          verify and process any transaction we deem, in our sole discretion, to
          be fraudulent, suspicious, in violation of these terms and conditions,
          or that we believe may impose potential liability on Opt Health or the
          Service Provider, their subsidiaries, affiliates or any of their
          respective officers, directors, employees, representatives and agents.
        </Text>
        <Text>
          Limitations. Referrer’s unique invitation link may not be shared,
          sold, or used in any way except by the Referrer alone to obtain
          Qualified Referrals.
        </Text>
        <Text>
          Data. Referred client agrees that Referrer will be informed of their
          membership status for purposes of providing Referral Rebates.
        </Text>
        <Text>
          Compliance with Applicable Law. As a Referrer, you agree to comply
          with all applicable law, including, but not limited to the CAN SPAM
          Act. Any distribution of your Referral Program link that could
          constitute unsolicited commercial email or “spam” under any applicable
          law or regulation is expressly prohibited and will be grounds for
          immediate termination of your Account and exclusion from Opt Health
          Referral Program.
        </Text>
        <Text>
          Right to Terminate Accounts. Opt Health reserves the right to
          terminate the Account of any Referrer and/or Referred client at any
          time and without notice, including, but not limited to, if we, at our
          sole discretion consider any Referrer and/or Referred client’s conduct
          to be in violation of these Referral Program Terms and Conditions.
        </Text>
        <Text>
          Right to Cancel Program or Change Terms. Opt Health reserves the right
          to cancel the Referral Program and to revise these Referral Program
          Terms from time to time. If the Referral Program is terminated, then
          any unclaimed Referral Rebates will be forfeited at that time.
        </Text>
        <Text>
          You hereby release Opt Health and the Service Provider of all claims
          and liabilities arising from or relating to the Referral Rebates
          received by you.
        </Text>
        <Text>
          In no event will Opt Health and the Service Provider be liable for any
          indirect, incidental, special, punitive or consequential damages
          arising from or relating to the Bonuses and/or the Program. All taxes
          owed with respect to any Referral Rebates shall be the sole
          responsibility of the recipient.
        </Text>
        {/* <Heading textAlign={"center"}>CONSENT</Heading>
        <Text>
          By signing this form, I voluntarily understand, acknowledge, and agree
          to the following with respect to medical services rendered by Opt
          Health CA P.C., a California medical corporation, and their physicians
          (each a “Physician”), associates, technical assistants, agents, and
          other healthcare providers (collectively, “the Practice”).
        </Text>
        <Text>
          I request, voluntarily consent to, and authorize the services and
          treatment described herein, including without limitation telemedicine
          consultation, preventive medicine services, sexual wellness services,
          hormone supplementation therapy, peptide treatments, as well as those
          additional services that are advisable in a Physician’s professional
          judgment that may be rendered.
        </Text>
        <Text>
          I understand that no promises have been made to me about the results
          of any treatments or services.
        </Text>
        <Text>
          I understand that I have read and understood each of the provisions
          appearing on this consent form. I also acknowledge that I have had the
          opportunity to ask any questions that I may have, and by my electronic
          agreement, I consent and agree to all provisions herein both
          individually and collectively.
        </Text>
        <Text>
          The consent will remain fully effective until it is revoked in
          writing. I have the right at any time to discontinue services
        </Text> */}
        {/* <Heading as="h2">Not Primary Care; Limit of Role</Heading>
        <Text>
          The Practice is acting in a specialist, supportive, consultative
          capacity and not as a primary care physician and does not provide
          emergency care. Accordingly, The Practice is not replacing care
          currently provided to me by other physicians, such as my current
          primary care physician, internist, urologist, cardiologist,
          gastroenterologist, or other specialty care provider. The Practice has
          advised me that the Practice does not admit patients to the hospital
          or treat hospitalized patients, and that I should maintain a
          relationship with a physician who is available to provide emergent and
          urgent care. The Practice does not provide immediate on-call services.
        </Text>
        <Text fontWeight={"bold"} textDecor={"underline"}>
          IF I ENCOUNTER A MEDICAL EMERGENCY, I WILL CONTACT 911 OR REPORT TO A
          HOSPITAL EMERGENCY DEPARTMENT.
          <Text as="span" fontWeight={"normal"} textDecor={"none"}>
            If at any time I feel my condition has progressed, worsened, or has
            not improved over a long period of time, I agree to go to the
            nearest emergency room immediately.
          </Text>
        </Text>
        <Text fontWeight={"bold"}>
          I UNDERSTAND THAT THE PRACTICE IS CASH PAY ONLY AND DOES NOT SUBMIT
          CLAIMS OR BILLS FOR REIMBURSEMENT BY INSURANCE OR MEDICARE. I
          ACKNOWLEDGE AND AGREE THAT THE SERVICES MAY NOT BE COVERED BY
          INSURANCE OR MEDICARE.
        </Text>
        <Text fontWeight={"bold"}>
          I AGREE THAT ALL PERSONAL INFORMATION I PROVIDE, INCLUDING MEDICAL
          INFORMATION, IS TRUTHFUL, HONEST, AND ACCURATE; AND I EXPRESSLY WAIVE
          AND RELEASE ANY CLAIMS I MAY HAVE AGAINST THE PRACTICE AND THE
          PHYSICIAN FOR INJURIES OR DAMAGES THAT ARISE OUT OF UNTRUTHFUL,
          DISHONEST, OR INACCURATE INFORMATION THAT I PROVIDE.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Telemedicine Consent
          </Text>
          : I understand that the Practice may use telemedicine mechanisms to
          consult for, discuss, and recommend treatment. I also understand that
          telemedicine involves the delivery of clinical health care services by
          electronic communication (including two-way audio-visual
          communication), as defined by applicable law. Use of telemedicine
          services by the Practice can result in benefits such as improved
          access to care and decreased exposure to community spread and person
          to person spread of illnesses. Potential risks include gaps of
          failures in communication, complicating healthcare decision-making,
          notwithstanding reasonable efforts to ensure the quality and
          reliability of transmitted information. There may be limitations to
          image quality or other electronic problems that are beyond the control
          of the Practice. Despite reasonable security measures, online
          communications can be forwarded, intercepted, or even changed or
          falsified without my knowledge. I understand that the laws that
          protect privacy and the confidentiality of medical information also
          apply to telemedicine. I understand and agree that during my
          telemedicine consultation, in addition to the Physician conducting the
          consultation, another Physician may be observing my consultation for
          training purposes. I fully understand, request, and agree to
          participate in telemedicine services.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Hormone Supplementation Therapy Consent
          </Text>
          : I request and consent to the administration of hormones and and oral
          supplements and authorize that these will be prescribed to me by the
          Practice. I acknowledge that there are no guarantees or assurances
          made with respect to the benefit of hormone supplementation therapy
          prescribed for me. I understand that I will be in charge of
          injecting/administering any hormones or supplements prescribed to me.
          I will conform and comply with the recommended doses and methods of
          administration. I understand that initial blood tests will be
          performed to establish my baseline hormone levels. I agreed to comply
          with requests for ongoing testing to assure proper monitoring of my
          hormone levels. I agreed to report to the physicians any adverse
          reaction or problems that might be related to my hormone therapy. I
          understand that with hormone supplementation there are possible risks
          and complications. I have not been promised or guaranteed any specific
          benefit from the administration of this therapy. I understand that
          there are no guarantees with respect to any hormone supplementation
          treatments prescribed. I understand that the role of the Physician may
          be for hormone therapy only. I agree that I am and will always be
          under the care of another physician for all other medical conditions.
          I have been informed that insurance companies and Medicare do not pay
          for hormone supplementation therapy. I therefore agree to pay for all
          services and charges myself, with the understanding that I will not be
          reimbursed by my insurance company. I have read and understood all of
          the above. I have had other information given to me about hormone
          supplementation therapy so that I fully understand what I am signing
          and hereby request and consent to treatment using hormone
          supplementation therapy.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Prescription Policy
          </Text>
          : I understand that there is no guarantee a prescription will be
          written by a Physician. Physicians shall use their own discretion and
          professional judgment to prescribe DEA controlled or scheduled
          medications, or certain other drugs which may be harmful because of
          their potential for abuse. Physicians reserve the right to deny care
          for actual or potential misuse of medical care and any associated
          prescriptions. I agree that any prescriptions that I acquire from a
          Physician or the Practice will be solely for my individual personal
          use. I agree to fully and carefully read all provided prescription
          information and labels and to contact a Physician or pharmacist if I
          have any questions regarding the prescription.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Email, Phone, & Text Consent
          </Text>
          : If at any time I provide an email or telephone number(s) at which I
          may be contacted, I consent to receiving appointment reminders and
          other healthcare communications/information at that email and/or
          telephone number(s) from the Practice via email, text message, push
          notification, and/or voicemail message. I agree that all agreements
          and consents can be signed electronically and all notices,
          disclosures, and other communications that the Practice provides to me
          electronically satisfy any and all legal requirements that such
          notices and other communications be in writing.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Duty to Inform
          </Text>
          : I also understand it is my responsibility to keep the Practice
          informed of the name and contact information of my primary care
          physician and treating specialists, of any diagnoses I have received
          and of any treatments I have had or am now undergoing for current
          conditions.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Physical Exam Acknowledgment
          </Text>
          : I have had an annual physical exam with my primary care physician of
          record.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Alternatives Approaches
          </Text>
          : As alternative approaches to my healthcare, the Practice encourages
          me to speak with and consider the advice of other physicians or
          appropriate healthcare practitioners regarding my overall care.
        </Text>
        <Text textDecor={"underline"}>Miscellaneous</Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            My Participation
          </Text>
          : I understand that I am responsible to disclose to the Practice all
          medication, care, treatment, diagnoses, and assessments that I receive
          elsewhere and am responsible to provide medical records from other
          providers to ensure that care is coordinated and compatible. Medical
          records can only be released with my authorization. I will need to
          obtain any records and/or labs that I would like the Practice to
          review.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Off-Label Use
          </Text>
          : I understand that the Practice may prescribe medications for uses
          other than those indicated by the drug manufacturer and approved by
          the federal Food and Drug Administration (off-label use). In such
          case, no one can be fully aware of all possible side effects and
          complications. The details of such off-label use including expected
          benefits, material risks and alternatives, have been explained to me
          in terms I understand. I have informed the Practice of all known
          allergies, and of all medications I am currently taking.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            No Claims or Guarantees
          </Text>
          : I understand that the Practice makes no representations, claims or
          guarantees that my medical problems or conditions will be cured,
          solved, or helped by undergoing treatment by the Practice.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Referrals
          </Text>
          : I understand that the Practice’s treatment may include
          recommendation that I seek other types of treatment from other health
          professionals who are not affiliated with the Practice. I understand
          that the Practice does not supervise these professionals and is not
          clinically or legally responsible for them. I understand that they are
          not the Practice’s employees and that they will bill separately for
          their services.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Assumption of Risk; Indemnity
          </Text>
          : I choose to receive care that may involve clinical innovation and/or
          may differ from conventional medicine. Accordingly, I knowingly,
          voluntarily, and intelligently assume all risks involved in the same.
        </Text>
        <Text>
          As a result of my assumption of these risks, I agree to release, hold
          harmless, indemnify, and defend Practice, the Physician, Opt Health,
          Inc., and all of their respective predecessors, successors, assigns,
          parent, subsidiaries, partners, employees, agents, officers,
          directors, representatives, attorneys, administrators, contractors,
          subcontractors and/or consultants, and each of them (collectively,
          “Indemnitees“), from and against any and all claims which I (or my
          representatives) may have for any loss, damage, or injury arising out
          of or in connection with use of the treatments or services described
          herein. Further, I agree not to pursue a frivolous claim against any
          of the Indemnitees, merely because I am dissatisfied with the results
          of the above treatments or services.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            AUTHORIZATION FOR RELEASE OF PROTECTED HEALTH INFORMATION
          </Text>
          : I hereby authorize the Practice to send an electronic laboratory
          requisition to me via email at the email address I provided to the
          Practice for the purpose of helping to facilitate an optimal
          laboratory experience when I visit the laboratory. I authorize the use
          or disclosure of my individually identifiable health information as
          described above for the purpose listed. I have the right to withdraw
          permission for the release of my information. If I consent to this
          authorization to use or disclose information, I can revoke that
          authorization at any time. The revocation must be made in writing and
          will not affect information that has already been used or disclosed. I
          have the right to receive a copy of this authorization. I am signing
          this authorization voluntarily and treatment, payment, or my
          eligibility for benefits will not be affected if I do not sign this
          authorization. I further understand that a person to whom records and
          information are disclosed pursuant to this authorization may not
          further use or disclose the medical information unless another
          authorization is obtained from me or unless such disclosure is
          specifically required or permitted by law.
        </Text>
        <Text>
          I hereby knowingly and voluntarily consent and state my preference to
          have the Practice communicate with me by email or standard SMS
          messaging regarding various aspects of my medical care, which may
          include, but shall not be limited to, lab requisitions, test results,
          prescriptions, appointments, and billing. I understand that email and
          standard SMS messaging are not confidential methods of communication
          and may be insecure. I further understand that, because of this, there
          is a risk that email and standard SMS messaging regarding my medical
          care might be intercepted and read by a third party. I also understand
          that message frequency varies and carrier rates may apply.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            COVID-19 Release
          </Text>
          : I acknowledge that due to the current COVID-19 outbreak that entry
          into my home, office, or selected location for the purpose of my
          in-person exam (the “Property“) may pose a significant risk and hazard
          to all persons in or around the Property. I further acknowledge that
          the Indemnitees cannot assure safety against COVID-19 to any
          individual, including me, entering the Property. I ACKNOWLEDGE THAT I
          AM VOLUNTARILY CHOOSING TO INVITE THE PRACTICE TO ENTER THE PROPERTY
          WITH FULL KNOWLEDGE AND UNDERSTANDING OF THE DANGER AND RISK INVOLVED
          AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND ALL RISKS OF INJURY OR
          DEATH RELATED TO SUCH ENTRY. In consideration for any and all entry
          and access to the Property pursuant to this release, I hereby agree
          for myself and for my next of kin, successors, assigns, agents,
          attorneys, representatives, administrators, contractors, employees and
          any other persons or entities who may claim through me (collectively,
          "Releasors"), that the Releasors hereby release and forever discharge
          the Indemnitees, from and with respect to any and all manner of
          actions, causes of action, in law or in equity, suits, claims,
          judgment and demands, damages, losses, attorneys’ fees, rights, or
          liabilities, of any nature whatsoever, whether class, derivative, or
          individual in nature, costs or expenses of any nature whatsoever,
          known or unknown, suspected or unsuspected, fixed or contingent, which
          the Releasors ever had, now have, claim to have or may hereafter in
          the future have against Indemnitees for personal injury, death,
          property damage and any and all other direct and consequential damages
          occasioned or resulting from any matter, cause, fact, thing, act or
          omission whatsoever, arising from or in connection solely with my
          access or entry into the Property pursuant to this Statement and
          Release. I understand that the facts upon which this release is made
          may hereafter turn out to be other than or different from the facts
          now known or believed to be true. I accept and assume the risk of the
          facts to be different than now known or believed to be true. I agree
          that this release shall be and remain in all respects effective and
          not subject to termination or rescission by virtue of any difference
          of facts. California Civil Code §1542, which is hereby expressly
          waived with respect to this release reads as follows: “A general
          release does not extend to claims that the creditor or releasing party
          does not know or suspect to exist in his or her favor at the time of
          executing the release and that if known by him or her would have
          materially affected his or her settlement with the debtor or released
          party.” I similarly waive any and all rights or benefits conferred by
          any statute, regulation, or principle of common law or civil law of
          the United States or any state, commonwealth, territory, or other
          jurisdiction thereof or of any foreign country or other foreign
          jurisdiction which is similar, comparable or equivalent to § 1542 of
          the California Civil Code.
        </Text>
        <Heading as="h2" textAlign={"center"}>
          POLICIES
        </Heading>
        <Text>
          By signing this form, I acknowledge that, with respect to services
          rendered by the Practice, I understand the following:
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Cancellation Fee
          </Text>
          : You will only be charged after you choose to consult with a doctor
          and your appointment time and payment information are confirmed. You
          can cancel your appointment and receive a full refund, provided that
          you cancel at least 24 hours before your appointment time. If you miss
          your scheduled appointment, or cancel less than 24 hours before your
          appointment time, the Practice will not issue a refund. You hereby
          authorize this charge by providing the information below.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            No Refunds Post Consult
          </Text>
          : The Practice does not offer any refund of any kind for consults
          after the consult has been performed.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Payments, Recurring Payments, Subscriptions
          </Text>
          : You understand by providing your payment information to the Practice
          you authorize the Practice to charge or facilitate the charge of such
          payment information, including for automatic recurring payments, for
          all services and/or treatments. Certain services are offered on a
          subscription basis. For subscription-based services, your chosen
          payment method will be charged at regular intervals automatically as
          described for that service.{" "}
          <Text fontWeight={"bold"}>
            You understand that when joining or signing up for
            subscription-based services, in which three months of products
            and/or medications are dispensed to you, you agree to be bound to at
            least a three-month subscription. If you cancel your subscription
            and/or terminate the payment method (and do not replace the payment
            method) for such subscription, you agree that you are responsible
            and obligated to pay for the remainder of the payments for such
            three-month subscription.
          </Text>{" "}
          Subject to the foregoing sentence, you may cancel a subscription at
          any time up to forty-eight (48) hours before the applicable monthly
          processing date of your subscription by emailing{" "}
          <Link href="mailto:info@getopt.com" textDecor={"underline"}>
            info@getopt.com
          </Link>
          . You understand that the cost of services, including medications, are
          final and not refundable (except as otherwise set forth herein). You
          understand you will not receive refunds for any treatments or
          medications, including unused or unopened treatments and medications,
          unless otherwise set forth herein. You agree that the Practice may
          store any payment information that you provide for the purposes of
          recurring payments that you have chosen to participate in as part of
          any membership or subscription programs or plans.
        </Text>
        <Text>
          If you are delinquent on any payments, the Practice reserves the right
          to discontinue services. The Practice may use third-party services for
          the purpose of facilitating payment and the completion of the
          purchases for services rendered by the Practice in conjunction with
          the services. By submitting your payment information, you grant the
          Practice the right to provide information to these third parties. You
          represent and warrant that (i) any payment information you supply is
          accurate, true, and complete, (ii) any charges incurred for services
          will be honored by your credit/debit card company or bank, (iii) you
          will pay all charges incurred by you including applicable taxes, and
          (iv) the payment card is in your name and you are authorized to use
          such card for purchases.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Digital Records
          </Text>
          : Provider may store medical and office records digitally. While the
          Practice will make reasonable efforts to keep the data secure
          according to legal requirements, and maintains the privacy and
          confidentiality of patient data, I understand that no system is 100%
          secure. You agree that Opt Health, Inc. and/or the Practice may
          de-identify your information such that it is no longer considered
          protected health information or personally identifiable information
          and may convey, sell, transmit or provide such de-identified
          information to third parties.
        </Text>
        <Text textDecor={"underline"}>
          Office Insurance Practices and Patient Financial Responsibility
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Payment
          </Text>
          : The Practice accepts credit cards only and is exclusively cash pay.
          I agree that the Practice may store any payment information that I
          provide for the purposes of recurring payments that I have chosen to
          participate in as part of any membership or subscription programs or
          plans.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            No Participation in Insurance Plans
          </Text>
          : The Practice is an out-of-network provider for services within this
          Practice; the Practice does not participate in any insurance panels,
          and does not accept assignment from any insurance company.
          <Text fontWeight={"bold"} textDecor={"underline"}>
            Consequently, I am responsible for payment in full and all charges
            as determined by the Practice.
          </Text>
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            No Responsibility To Determine Eligibility for Benefits
          </Text>
          : the Practice is not responsible for determining eligibility for
          benefits or for assisting me with collecting insurance benefits and
          has no responsibility to correspond with or telephone or email any
          insurer with which the Practice is an out-of-network provider.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            My Financial Responsibility
          </Text>
          : I am financially responsible for any charges for services. I also
          agree to be responsible for costs and expenses, including court costs,
          attorney fees and interest, should it be necessary for the Practice to
          take action to secure payment of an outstanding balance.
        </Text>
        <Text>
          <Text textDecor={"underline"} as="span">
            Charges
          </Text>
          : The Practice may recommend additional specific products or services
          such as prescriptions, blood or urine tests, or supplements that may
          be additional costs.
        </Text>
        <Text textDecor={"underline"}>
          Physician-Patient Arbitration Agreement
        </Text>
        <Text>
          Article 1: Agreement to Arbitrate: It is understood that any dispute
          as to medical malpractice, that is as to whether any medical services
          rendered under this contract were unnecessary or unauthorized or were
          improperly, negligently or incompetently rendered, will be determined
          by submission to arbitration as provided by California law, and not by
          a lawsuit or resort to court process except as California law provides
          for judicial review of arbitration proceedings. Both parties to this
          contract, by entering into it, are giving up their constitutional
          right to have any such dispute decided in a court of law before a
          jury, and instead are accepting the use of arbitration.
        </Text>
        <Text>
          Article 2: All Claims Must be Arbitrated: It is the intention of the
          parties that this agreement bind all parties whose claims may arise
          out of or relate to treatment or service provided by the physician
          including any spouse or heirs of the patient and any children, whether
          born or unborn, at the time of the occurrence giving rise to any
          claim. In the case of any pregnant mother, the term “patient” herein
          shall mean both the mother and the mother’s expected child or
          children. All claims for monetary damages exceeding the jurisdictional
          limit of the small claims court against the physician, and the
          physician’s partners, associates, association, corporation or
          partnership, and the employees, agents and estates of any of them,
          must be arbitrated including, without limitation, claims for loss of
          consortium, wrongful death, emotional distress or punitive damages.
          Filing of any action in any court by the physician to collect any fee
          from the patient shall not waive the right to compel arbitration of
          any malpractice claim.
        </Text>
        <Text>
          Article 3: Procedures and Applicable Law: A demand for arbitration
          must be communicated in writing to all parties. Each party shall
          select an arbitrator (party arbitrator) within thirty days and a third
          arbitrator (neutral arbitrator) shall be selected by the arbitrators
          appointed by the parties within thirty days of a demand for a neutral
          arbitrator by either party. Each party to the arbitration shall pay
          such party’s pro rata share of the expenses and fees of the neutral
          arbitrator, together with other expenses of the arbitration incurred
          or approved by the neutral arbitrator, not including counsel fees or
          witness fees, or other expenses incurred by a party for such party’s
          own benefit. The parties agree that the arbitrators have the immunity
          of a judicial officer from civil liability when acting in the capacity
          of arbitrator under this contract. This immunity shall supplement, not
          supplant, any other applicable statutory or common law. Either party
          shall have the absolute right to arbitrate separately the issues of
          liability and damages upon written request to the neutral arbitrator.
        </Text>
        <Text>
          The parties consent to the intervention and joinder in this
          arbitration of any person or entity which would otherwise be a proper
          additional party in a court action, and upon such intervention and
          joinder any existing court action against such additional person or
          entity shall be stayed pending arbitration. The parties agree that
          provisions of California law applicable to health care providers shall
          apply to disputes within this arbitration agreement.
        </Text>
        <Text>
          Article 4: General Provisions: All claims based upon the same
          incident, transaction or related circumstances shall be arbitrated in
          one proceeding. A claim shall be waived and forever barred if (1) on
          the date notice thereof is received, the claim, if asserted in a civil
          action, would be barred by the applicable California statute of
          limitations, or (2) the claimant fails to pursue the arbitration claim
          in accordance with the procedures prescribed herein with reasonable
          diligence. With respect to any matter not herein expressly provided
          for, the arbitrators shall be governed by the California Code of Civil
          Procedure provisions relating to arbitration.
        </Text>
        <Text>
          Article 5: Revocation: This agreement may be revoked by written notice
          delivered to the physician within 30 days of signature. It is the
          intent of this agreement to apply to all medical services rendered any
          time for any condition.
        </Text>
        <Text>
          Article 6: Retroactive Effect: If patient intends this agreement to
          cover services rendered before the date it is signed (including, but
          not limited to, emergency treatment) patient should initial below:
        </Text>
        <Text>
          If any provision of this arbitration agreement is held invalid or
          unenforceable, the remaining provisions shall remain in full force and
          shall not be affected by the invalidity of any other provision.
        </Text>
        <Text>
          I understand that I have the right to receive a copy of this
          arbitration agreement. By my signature (electronic or otherwise), I
          acknowledge that I have received a copy.
        </Text>
        <Text>
          NOTICE: BY SIGNING THIS AGREEMENT YOU ARE AGREEING TO HAVE ANY ISSUE
          OF MEDICAL MALPRACTICE DECIDED BY NEUTRAL ARBITRATION AND YOU ARE
          GIVING UP YOUR RIGHT TO A JURY OR COURT TRIAL. SEE ARTICLE 1 OF THIS
          AGREEMENT.
        </Text>
        <Text fontWeight={"bold"}>
          I have carefully read this entire document, which is printed in
          English, and acknowledge that English is a language I read and
          understand, and that I understand the document. I do not feel rushed
          or impaired, nor am I under the influence of a sedative or
          sleep-inducing medication.
        </Text>
        <Text fontWeight={"bold"}>
          I accept and agree to all of the terms above. I am free to refuse or
          withdraw my consent and to discontinue participation in any treatment,
          service, or research at any time without fear of reprisal against or
          prejudice to me. I may request and receive a copy of this document
          from the Practice. If any portion of this form is held invalid, the
          rest of the document will continue in full force and effect.
        </Text> */}
      </Box>
    </BaseLayout>
  );
};

import React from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  Flex,
  Container,
  Image
} from "@chakra-ui/react";
import teamMembers from "../../data/team.data";

interface CardProps {
  heading: string;
  description: string;
  imageSrc: string;
}

const Card = ({ heading, description, imageSrc }: CardProps) => {
  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          justifyContent="center"
          alignItems="center"
          mx={"auto"}
          overflow="hidden"
        >
          <Image
            boxSize="250px"
            objectFit="cover"
            borderRadius={"full"}
            src={imageSrc}
            alt="Dan Abramov"
          />
        </Flex>
        <Box textAlign={"center"} mx={"auto"} mt={1}>
          <Heading
            fontSize="22px"
            className="font-mantblack"
            style={{
              fontFamily: "Montblancbold"
            }}
            color={"#0b3b3c"}
          >
            {heading}
          </Heading>
          <Text mt={1} fontSize={"lg"} color={"#0b3b3c"}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default function Team() {
  return (
    <Box py={10}>
      <VStack spacing={2} textAlign="center" px={5}>
        <Heading
          as="h2"
          fontSize={{ base: "3xl", md: "4xl", lg: "65px" }}
          className="font-mantblack"
          style={{
            fontFamily: "Montblancbold"
          }}
        >
          <Text color={"#01688b"} as={"span"}>
            MEET YOUR WORLD CLASS
          </Text>
          <br />
          <Text
            as="span"
            style={{
              WebkitTextStroke: "1px #01688B",
              color: "transparent"
            }}
          >
            MEDICAL TEAM
          </Text>
        </Heading>
        <Text
          fontSize={{ base: "md", lg: "lg" }}
          color={"#444444"}
          fontWeight={"semibold"}
        >
          {`Opt Health works with elite doctors specializing in men’s health.`}
        </Text>
      </VStack>
      <Container maxW={"full"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {teamMembers.map((member) => (
            <Card
              key={member.name}
              heading={member.name}
              description={member.role}
              imageSrc={member.imageSrc}
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
}

import { useEffect, useState } from "react";
import { getLeads } from "../../../config/apis";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ListItem,
  OrderedList,
  Link,
  Skeleton
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
const columns = ["ID", "Full Name", "Email", "Links", "Customers", "Draft"];
type lead = {
  full_name: string;
  email: string;
  link: string[];
  school: { name: string };
  customers: string[];
  is_draft: boolean;
  id: number;
};
export const Leads = () => {
  const [leads, setLeads] = useState<lead[]>([] as lead[]);
  const { customer_id } = useParams();
  const _getLeads = async () => {
    try {
      const response = await getLeads(Number(customer_id));
      // console.log(response);

      setLeads(response.leads);
    } catch (error) {}
  };
  useEffect(() => {
    _getLeads();
  }, []);
  return (
    <Skeleton isLoaded={!!leads.length}>
      <TableContainer bgColor={"white"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {leads && leads?.length ? (
              leads.map((lead: lead) => {
                return (
                  <Tr key={lead.email}>
                    <Td>{lead.id}</Td>
                    <Td>{lead.full_name}</Td>
                    <Td>{lead.email}</Td>
                    <Td>
                      <OrderedList>
                        {lead.link.map((link, index) => (
                          <ListItem key={index}>
                            <Link
                              href={"https://" + link}
                              isExternal
                              color="blue"
                            >
                              {"https://" + link}
                            </Link>
                          </ListItem>
                        ))}
                      </OrderedList>
                    </Td>
                    {/* <Td>{lead.school.name}</Td> */}
                    <Td>
                      <OrderedList>
                        {lead.customers.map((customer, index) => (
                          <ListItem key={index}>{customer}</ListItem>
                        ))}
                      </OrderedList>
                    </Td>
                    <Td>{lead.is_draft.toString()}</Td>
                  </Tr>
                );
              })
            ) : (
              <Tr></Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  );
};

import { useEffect, useState } from "react";
import { getAnalytics, getLeads } from "../../../config/apis";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ListItem,
  OrderedList,
  Link,
  Skeleton,
  Flex,
  Select,
  Button,
  Box,
  Text
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const columns = [
  "User",
  "Total Users",
  "Views",
  "New Users",
  "Sessions Per User",
  "Screen Page Views PerUser"
];
const customers = [
  {
    name: "Pomelo",
    value: "pomelo"
  },
  {
    name: "Lyka",
    value: "lyka"
  },
  {
    name: "Opt Health",
    value: "getopt"
  },
  {
    name: "Knowledgehook",
    value: "knowledgehook"
  }
];
type row = {
  page: string;
  totalUsers: string;
  views: string;
  newUsers: string;
  sessionsPerUser: string;
  screenPageViewsPerUser: string;
};
export const AnalyticsDashboard = () => {
  const [data, setData] = useState<row[]>([] as row[]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [customerName, setCustomerName] = useState("pomelo");
  const [isLoading, setIsLoading] = useState(false);

  const _getData = async () => {
    setIsLoading(true);
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const response = await getAnalytics({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        customerName
      });
      // console.log(response);

      setData(response.data);
      setIsLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    _getData();
  }, []);
  const formatDate = (date: Date | undefined) => {
    if (!date) {
      return "";
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };
  return (
    <Skeleton isLoaded={!!data.length || !isLoading}>
      <Flex
        gap={5}
        flexDir={["column", "row"]}
        alignItems={"center"}
        justifyContent={"space-around"}
        p={5}
      >
        <Box w="30%">
          <Text>Customer Name</Text>
          <Select
            placeholder="Customer Name"
            value={customerName}
            focusBorderColor="#01688b"
            bgColor={"white"}
            name="customer"
            onChange={(e) => {
              setCustomerName(e.target.value);
            }}
          >
            {customers.map((customer) => (
              <option value={customer.value}>{customer.name}</option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text>Start Date</Text>

          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date as Date)}
            maxDate={endDate || new Date()}
          />
        </Box>
        <Box>
          <Text>End Date</Text>

          <DatePicker
            selected={endDate}
            onChange={(date) => {
              if (startDate && date) {
                if (startDate > date) {
                  setStartDate(undefined);
                }
              }
              setEndDate(date as Date);
            }}
            maxDate={new Date()}
          />
        </Box>
        <Button colorScheme="blue" onClick={_getData}>
          Filter
        </Button>
      </Flex>
      <TableContainer bgColor={"white"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data && data?.length && !isLoading ? (
              data.map((row: row) => {
                return (
                  <Tr key={row.page}>
                    <Td>{row.page}</Td>
                    <Td>{row.totalUsers}</Td>
                    <Td>{row.views}</Td>
                    <Td>{row.newUsers}</Td>
                    <Td>{row.sessionsPerUser}</Td>
                    <Td>{row.screenPageViewsPerUser}</Td>
                  </Tr>
                );
              })
            ) : (
              <Tr></Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Skeleton>
  );
};

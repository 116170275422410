import { Box, Container, Flex, Text, Image } from "@chakra-ui/react";
import "./HowItWorksSection.css";
import { motion } from "framer-motion";

const HowItWorksSection = () => {
  return (
    <Container maxW="5xl" w="full" mx="auto" pb={{ base: 0, lg: "20" }}>
      <Flex
        gap={{ base: "5", md: "10", lg: "20" }}
        alignItems="center"
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box flex="1" fontFamily={"PP Neue Montreal"}>
          <Text
            as="h2"
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="600"
            className="font-fragment"
          >
            How it works
          </Text>
          <Text fontSize="sm" color="#190e34" mt="7">
            Pomelo is a Family Plan for sharing credit internationally. You are
            the primary cardholder. You can invite up to 3 authorized users.
            Each will get their own card, designed with ❤️ in San Francisco,
            delivered to the Philippines 🇵🇭 in 10 days or less.
          </Text>
        </Box>
        <Box flex="1" pos="relative">
          <Image
            src="/img/section-06-img-1.svg"
            pos="relative"
            zIndex="99999"
            mx="auto"
          />
          <Box
            pos="relative"
            top="0"
            left="0"
            zIndex="9999"
            display={{ base: "none", lg: "flex" }}
          >
            <motion.img
              src="/img/section-06-img-2.svg"
              animate={{ x: 170, y: -40 }}
              style={{ position: "relative", left: "0", zIndex: "999" }}
            />
            <motion.img
              src="/img/section-06-img-6.svg"
              animate={{ x: -60, y: 90, rotate: 4 }}
              style={{ position: "relative", left: "0", zIndex: "999" }}
            />
            <motion.img
              src="/img/section-06-img-7.svg"
              animate={{ x: -350, y: 110, rotate: 8 }}
              style={{ position: "relative", left: "0", zIndex: "99" }}
            />
            <motion.img
              src="/img/section-06-img-8.svg"
              animate={{ x: -640, y: 130, rotate: 12 }}
              style={{ position: "relative", left: "0", zIndex: "9" }}
            />
            <motion.img
              src="/img/section-06-img-3.svg"
              animate={{ x: -560, y: 160 }}
              style={{ position: "relative", left: "0", zIndex: "999" }}
            />
            <motion.img
              src="/img/section-06-img-4.svg"
              animate={{ x: -700, y: 210 }}
              style={{ position: "relative", left: "0", zIndex: "999" }}
            />
            <motion.img
              src="/img/section-06-img-5.svg"
              animate={{ x: -860, y: 250 }}
              style={{ position: "relative", left: "0", zIndex: "999" }}
            />
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default HowItWorksSection;

import { Box, Image, Text } from "@chakra-ui/react";

type Props = {
  image: string;
  heading: string;
  subHeading: string;
};

export const FeaturesCard = ({ image, heading, subHeading }: Props) => {
  return (
    <Box>
      <Image src={image} />
      <Text fontSize={"22px"} fontWeight={"bold"} color={"black"}>
        {heading}
      </Text>
      <Text>{subHeading}</Text>
    </Box>
  );
};

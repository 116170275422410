import { useNavigate } from "react-router-dom";
import { ALLOWED_TERMS_PAGE_HOSTS } from "../../config/constants";

export const TermsWrapper = () => {
  const host = window.location.host;

  const Component = ALLOWED_TERMS_PAGE_HOSTS[host];
  const navigate = useNavigate();

  if (ALLOWED_TERMS_PAGE_HOSTS[host]) {
    return <Component />;
  } else {
    return <div></div>;
  }
};

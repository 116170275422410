const designforyoursucessData = [
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/08/32432.png",
    title: "Measure",
    firstText:
      "Sign up to book your in-home nurse exam* or appointment with one of our local lab partners to complete your baseline blood draw. Opt Health is secure and HIPPA-compliant.",
    secondText:
      "Attend your in-home nurse exam or appointment with one of our local lab partners. All results are private and sent directly to your Opt Health doctor.",
  },
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/07/2.png",
    title: "Mentor",
    firstText:
      "Meet your physician to discuss the results of your blood work & your health goals. Align with your doctor’s recommended optimization protocol customized to you.",
    secondText:
      "Prescribed medications arrive discreetly at your doorstep in 5-7 business days.",
  },
  {
    imageSrc: "https://getopt.com/wp-content/uploads/2022/07/3.png",
    title: "Monitor",
    firstText:
      "Link your wearable devices to your Opt Health Dashboard to monitor your performance score over time.",
    secondText:
      "Attend your follow-up consultations with your doctor every 3 or 6-months, discuss your results, and make adjustments to hit your ongoing health goals.",
  },
];

export default designforyoursucessData;

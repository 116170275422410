import { formDataType, khPaymentPayloadType, loginCreds } from "../../types/";
// export const baseURl = "https://api.rfr.us";

export const baseURl =
  process.env.REACT_APP_BASE_URL ||
  "https://26a3-111-88-209-185.ngrok-free.app";
// export const baseURl = "https://api.dev.refer.us";
export const extractAndFetch = async () => {
  const domain = window.location.host;
  let slug = window.location.pathname.split("/")[1];
  if (!slug) {
    slug = "knowledgehook";
  }
  slug = decodeURIComponent(slug);
  const fetchResponse = await fetch(`${baseURl}/user`, {
    method: "POST",
    body: JSON.stringify({
      domain,
      slug
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const postData = async (params: formDataType) => {
  const domain = window.location.host;
  const slug = window.location.pathname.split("/")[1];

  const fetchResponse = await fetch(`${baseURl}/create-lead`, {
    method: "POST",
    body: JSON.stringify({
      domain,
      slug,
      ...params
    }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const getSchools = async (countryCode: string, stateCode: string) => {
  const fetchResponse = await fetch(
    `${baseURl}/schools?countryCode=${countryCode}&stateCode=${stateCode}`,

    {
      method: "GET"
    }
  ).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const loginApi = async (credentials: loginCreds) => {
  const fetchResponse = await fetch(`${baseURl}/admin/login`, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(async (res) => ({ ...(await res.json()), status: res.status }));
  return fetchResponse;
};

export const getLeads = async (customer_id?: number) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user.token) {
    return { message: "Unauthorized" };
  }

  const fetchResponse = await fetch(`${baseURl}/admin/leads`, {
    method: "POST",
    body: JSON.stringify({ customer_id }),
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};
export const getCustomers = async () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user.token) {
    return { message: "Unauthorized" };
  }

  const fetchResponse = await fetch(`${baseURl}/admin/customers`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};
export const getAnalytics = async (params: {
  startDate?: string;
  endDate?: string;
  customerName: string;
}) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user.token) {
    return { message: "Unauthorized" };
  }

  const fetchResponse = await fetch(`${baseURl}/admin/analytics`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: user.token
    }
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const handleSubscription = async (params: khPaymentPayloadType) => {
  const domain = window.location.host;

  const fetchResponse = await fetch(`${baseURl}/create-subscription`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ...params, domain })
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const validateUserExistence = async (email: string) => {
  const domain = window.location.host;

  const fetchResponse = await fetch(`${baseURl}/validate`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email, domain })
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const validateName = async (fullName: string) => {
  const fetchResponse = await fetch(`${baseURl}/validate-name`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ fullName })
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const calculateTax = async ({
  country,
  state,
  fetch_from_stripe,
  postal_code
}: {
  country: string;
  state: string;
  fetch_from_stripe: boolean;
  postal_code?: string;
}) => {
  const fetchResponse = await fetch(`${baseURl}/calculate-tax`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ country, state, fetch_from_stripe, postal_code })
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

export const addTempLead = async ({
  user_identifier,
  lead_id
}: {
  user_identifier: string;
  lead_id: number;
}) => {
  const domain = window.location.host;
  const fetchResponse = await fetch(`${baseURl}/temp_leads`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ user_identifier, domain, lead_id })
  }).then((res) => {
    return res.json();
  });
  return fetchResponse;
};

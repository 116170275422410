import { Spinner, Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTo = window.localStorage.getItem("redirectTo") as string;
    navigate(redirectTo);
  }, []);
  return (
    <Box
      display={"flex"}
      h="100%"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Spinner size={"xl"} />
    </Box>
  );
};
